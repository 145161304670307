import React from 'react'
import Logo from "../../assets/images/logo.svg";
import india_map from "../../assets/images/india_map.png";
import dubai_map from "../../assets/images/dubai_map.png";
import Instagram from "../../assets/images/con_insta.png";
import Whatsapp from "../../assets/images/con_whtsapp.png";
import Linkedin from "../../assets/images/con_linkedin.png";
import Twitter from "../../assets/images/con_twiter.png";
import contact_banner_img1 from "../../assets/images/contact_banner_img1.png";
import contact_banner_img2 from "../../assets/images/contact_banner_img2.png";
import contact_bar from "../../assets/images/contact_bar.png";
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate();
    return (
        <div>
            <div className='let_connect_section'>
                <div className="container">
                    <div className='row justify-content-between'>
                        <div className='col-lg-6 col-md-6'>
                            <div className='let_connect_left'>
                                {/* <img src={contact_bar} alt="" className='contact_bar' /> */}
                                <h1 className='let_connect_head'>Let's <img src={contact_banner_img1} alt="" className='lets_connect_img' /> work</h1>
                                <h1 className='let_connect_head'><img src={contact_banner_img2} alt="" className='lets_connect_img2' /> together</h1>
                                {/* <img src={contact_bar} alt="" className='contact_bar2' /> */}
                            </div>

                        </div>
                        <div className='col-lg-6 d-flex justify-content-end align-items-center col-md-6'>
                            <div className='let_connect_right'>
                                <button onClick={() => navigate("/ContactUs")} className='button_white'>Get In Touch</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='logo_section_footer'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="logo_section">
                                <div>
                                    <img className="logo-img" src={Logo} alt="Logo" />
                                </div>
                                <p className="mt-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <div className='d-flex mt-3'>
                                    <div className='social_media'>
                                        <i class="fa-brands fa-instagram"></i>
                                    </div>
                                    <div className='social_media'>
                                        <i class="fa-brands fa-whatsapp"></i>
                                    </div>
                                    <div className='social_media'>
                                        <i class="fa-brands fa-twitter"></i>
                                    </div>
                                    <div className='social_media'>
                                        <i class="fa-brands fa-linkedin-in"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='address_div2 d-flex'>
                                <div className='me-4'>
                                    <img src={india_map} alt="" className='map_images' />
                                </div>
                                <div>
                                <h5 className='mb-3'>India</h5>
                                    <p>No. 14-16, Global Hospital Road Junction, Indira Priyadarshini Nagar, Opp. ICICI Bank, Perumbakkam, Chennai, Tamil Nadu 600100</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='address_div2 d-flex'>
                                <div className='me-4'>
                                    <img src={dubai_map} alt="" className='map_images' />
                                </div>
                                <div>
                                    <h5 className='mb-3'>Dubai</h5>
                                    <p>No. 14-16, Global Hospital Road Junction, Indira Priyadarshini Nagar, Opp. ICICI Bank, Perumbakkam, Chennai, Tamil Nadu 600100</p>
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className='row d-flex justify-content-between footer_link_columns'>
                    <div className='col-lg-2 col-6 col-md-4'>
                        <div className='footer_boxes d-flex flex-column'>
                            <h5 className='mb-4'>About us</h5>
                            <a className='footer_links' href="">About</a>
                            <a className='footer_links' href="">Our Team</a>
                            <a className='footer_links' href="">Careers</a>
                            <a className='footer_links' href="">Blogs</a>
                        </div>
                    </div>
                    <div className='col-lg-2 col-6 col-md-4'>
                        <div className='footer_boxes d-flex flex-column'>
                            <h5 className='mb-4'>Services</h5>
                            <a className='footer_links' href="">Academy</a>
                            <a className='footer_links' href="">Agency</a>
                            <a className='footer_links' href="">Advisory</a>
                            <a className='footer_links' href="">Tech Development</a>
                        </div>
                    </div>
                    <div className='col-lg-2 col-6 col-md-4'>
                        <div className='footer_boxes d-flex flex-column'>
                            <h5 className='mb-4'>Resources</h5>
                            <a className='footer_links' href="">Blog</a>
                            <a className='footer_links' href="">Case Studies</a>
                            <a className='footer_links' href="">Useful Downloads</a>
                            <a className='footer_links' href="">FAQs</a>
                        </div>
                    </div>
                    <div className='col-lg-2 col-6 col-md-4'>
                        <div className='footer_boxes d-flex flex-column'>
                            <h5 className='mb-4'>Contact us</h5>
                            <a className='footer_links' href="">Support</a>
                            <a className='footer_links' href="">Sales</a>
                            <a className='footer_links' href="">Locations</a>
                            <a className='footer_links' href="">Contact Form</a>
                        </div>
                    </div>
                    <div className='col-lg-2 col-7 col-md-4'>
                        <div className='footer_boxes d-flex flex-column'>
                            <h5 className='mb-4'>Connect</h5>
                            <a className='footer_links' href="">Newsletter</a>
                            <a className='footer_links' href="">Social Media</a>
                            <a className='footer_links' href="">Events</a>
                            <a className='footer_links' href="">Community Involvement</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer_bottom d-flex mt-4'>
                <div className="container">
                    <div className='d-flex footer_links_section'>
                        <div className='copy_right'>
                            <p>© 2024 Social Eagle. All rights reserved.</p>
                        </div>
                        <div className='footer_links_section'>
                            <a className='footer_links' href="">Privacy Policy</a>
                            <a className='footer_links' href="">Terms of Service</a>
                            <a className='footer_links' href="">Cookie Policy</a>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Footer