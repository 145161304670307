import React from 'react'
import images from "../../../../helpers/images"

const ContentSection = () => {
    return (
        <div className='Agency_detail_page'>
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='purpose_left'>
                                <h1> Pay-Per-Click  <br className='break_tag' />(PPC) </h1>
                                <button className='button_red mt-4 agency_into_button'>Learn More</button>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='purpose_right pe-5'>
                                <p>You’ve probably noticed those promoted listings at the top of Google search results.
                                    They’re the prime spots on the page. To secure one of these top positions,
                                    you'll need to run your own pay-per-click (PPC) ads. While these ads do cost money each time someone
                                    clicks on them, they can deliver impressive returns when managed well. At Social Eagle, a PPC
                                    agency in Dubai, we have the expertise to
                                    create high-performing PPC campaigns that maximise your ROI without overspending.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='why_digital_market_left'>
                                <h2 className='headings_text_white'>What is PPC?<br className='break_tag' />Management?</h2>
                                <img src={images.Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-4' />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='why_digital_market_right'>
                                <p className='paraWhite_margin_top'>PPC, or pay-per-click, is a form of advertising where you only pay when someone clicks on your ad. When done correctly, it can bring you targeted traffic at a lower cost compared to other ad methods. But if done poorly, it can drain your budget without generating sales. Which result do you think you'll get with Social Eagle? The right one, of course.</p>
                                <p className='paraWhite_margin_top'>Google is the most popular platform for PPC ads, but they're also found on other search engines (yes, some still use Bing!) and e-commerce sites like Amazon. No matter what kind of PPC advertising you need in Dubai, our experts are here to make your campaigns shine</p>
                                <button className='button_white mt-4'> Get Consultation Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing'>
                <div className='row'>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }}>
                            <div className='container'>
                                <h2 className='headings_text_white'>Why Choose PPC from Social Eagle?</h2>
                                <p className='paraWhite_margin_top mb-5'>Our PPC (Pay-Per-Click) Advertising services help you drive targeted traffic to your website quickly and efficiently</p>
                                <img src={images.social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                                <p className='paraWhite_margin_top mt-5'>With our PPC expertise, we help you achieve immediate visibility and generate leads, driving growth for your business. Let us elevate your PPC advertising!.</p>
                                <button className='button_red mt-5'> Get Consultation</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_rght'>
                            <div className='agency_detail_whyChoose_rght_content mt-0'>
                                <div>
                                    <img src={images.payper_icon1} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Strategic Ad Creation</h5>
                                    <p>We design eye-catching ads that grab attention and encourage clicks from your ideal customers.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.payper_icon2} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Targeted Campaigns</h5>
                                    <p> We use precise targeting to reach the right audience at the right time, maximising your ad spend.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.payper_icon3} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Performance Analysis</h5>
                                    <p>We continuously monitor and optimise campaigns to improve ROI and ensure your ads perform at their best.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContentSection