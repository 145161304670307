import React from 'react'
import images from "../../../../helpers/images"


const ContentSection = () => {
  return (
    <div className='Agency_detail_page'>
      <div className='intro section section_padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='purpose_left'>
                <h1>Social Media  <br className='break_tag' />Marketing </h1>
                <button className='button_red mt-4 agency_into_button'>Keen To Hear?</button>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='purpose_right pe-5'>
                <p>Ah, social media. Whether you love it or hate it, there’s no denying its power.
                  For some, it’s the best thing since the internet itself; for others, it’s a headache.
                  But no matter where you stand, your business needs a strong social media presence.
                  The good news? You don’t have to worry about crafting Facebook posts or creating TikTok videos.
                  Our Social Media team takes care of everything for you, so you can focus on what you do best.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='why_digital_market_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='why_digital_market_left section_padding_top'>
                <h2 className='headings_text_white'>Should You Use Social <br className='break_tag' />Media for Marketing?</h2>
                <img src={images.Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-4' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='why_digital_market_right section_padding'>
                <p className='text-white'>Absolutely! Social media is a powerful tool that should be at the heart of your marketing strategy. With billions of users worldwide, these platforms offer an unmatched opportunity to reach a massive audience. No business can afford to ignore that kind of potential reach.</p>
                <p className='paraWhite_margin_top'>However, social media marketing isn’t just about posting content. It’s about doing it right. When done poorly, it can waste time or even harm your brand. But when executed effectively, it can drive targeted traffic, boost engagement, and grow your business. Success comes from understanding your audience, creating compelling content, and strategically promoting it.</p>
                <p className='paraWhite_margin_top'>As a social media marketing agency in Dubai, we’ve got the expertise to help you craft the perfect strategy.</p>
                <p className='paraWhite_margin_top'>Ready to make your mark? Contact our social media management team today</p>
                <button className='button_white mt-4'>Lean More</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='agency_detail_listing'>
        <div className='row'>
          <div className='col-lg-6 px-0'>
            <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }}>
              <div className='container'>
                <h2 className='headings_text_white'>Why Choose Social Eagle’s SMM Services in Dubai?</h2>
                <p className='paraWhite_margin_top'>Why spend your cash on our bunch of social media superstars?</p>
                <p className='paraWhite_margin_top '>The answer is simple: we get results. It’s easy nowadays to say you’re a social media expert, but playing around on Facebook for seven hours a day isn’t a qualification.</p>
                <p className='paraWhite_margin_top'>Instead, the experts at our social media advertising agency are different.</p>
                <p className='paraWhite_margin_top'>We’ve done the groundwork to really understand the mechanics of social media marketing and know exactly how to make your brand stand out from the crowd..</p>
                <p className='paraWhite_margin_top mb-5'>Our Social Media Marketing services transform your brand's online presence and drive results on platforms like Facebook and Instagram.</p>
                <img src={images.social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                <p className='paraWhite_margin_top mt-5'>With our expertise, we help you harness the power of social media to build brand loyalty and drive growth.
                  Let us take your social media marketing to the next level!</p>
                <button className='button_red mt-5'> Get FREE Consultation</button>
              </div>
            </div>
          </div>
          <div className='col-lg-6 px-0'>
            <div className='agency_detail_whyChoose_rght'>
              <div className='agency_detail_whyChoose_rght_content mt-0'>
                <div>
                  <img src={images.SocialMarket_icon1} alt="../" className='our_value_icon' />
                </div>
                <div>
                  <h5 className='headings_text_white'>Strategic Campaigns</h5>
                  <p>We design targeted advertising campaigns that attract and convert your ideal customers.</p>
                </div>
              </div>
              <div className='agency_detail_whyChoose_rght_content'>
                <div>
                  <img src={images.SocialMarket_icon2} alt="../" className='our_value_icon' />
                </div>
                <div>
                  <h5 className='headings_text_white'>Compelling Storytelling</h5>
                  <p> We craft engaging content that tells your brand's story and resonates with your audience.</p>
                </div>
              </div>
              <div className='agency_detail_whyChoose_rght_content'>
                <div>
                  <img src={images.SocialMarket_icon3} alt="../" className='our_value_icon' />
                </div>
                <div>
                  <h5 className='headings_text_white'>Insights and Optimization</h5>
                  <p> We analyse campaign performance to uncover insights and continuously optimise for better results.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ContentSection