import React, { useEffect } from 'react'
import AdvisoryBanner from '../component/Advisory/AdvisoryBanner'
import AdvisoryContent from '../component/Advisory/AdvisoryContent'
import Navbar from '../component/Navbar/Navbar'
import Footer from '../component/Footer/Footer'

const Advisory = () => {
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
    return (
        <div className='advisory_main_component overflow-hidden'>
            <Navbar />
            <AdvisoryBanner />
            <AdvisoryContent />
            <Footer />
        </div>
    )
}

export default Advisory