import React from 'react'
import images from '../../helpers/images'

const BlogsBanner = () => {
    return (
        <div>
            <div className='container'>
                <div className='row'>
                    <div className='contact_banner_parent '>
                        <img src={images.contact_bar} alt="" className='contact_bar' />
                        <p className='mb-3'>Contact Us</p>
                        <h1 className='contact_banner_head'>Blogs <img src={images.contact_banner_img1} alt="" className='contact_banner_img' /> </h1>
                        <h1 className='contact_banner_head'><img src={images.contact_banner_img2} alt="" className='contact_banner_img2' /> MainPage</h1>
                        <img src={images.contact_bar_bottom} alt="" className='contact_bar2' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogsBanner
