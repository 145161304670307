import React from 'react'
import contact_banner_img1 from "../../assets/images/contact_banner_img1.png";
import contact_banner_img2 from "../../assets/images/contact_banner_img2.png";
import contact_bar from "../../assets/images/contact_bar.png";


const ContactUsBanner = () => {
  return (
    <div className='contact_parent_div position-relative'>
      <div className='container'>
        <div className='row'>
          <div className='contact_banner_parent '>
            {/* <img src={contact_bar} alt="" className='contact_bar' /> */}
            <p className='mb-3'>Contact Us</p>
            <h1 className='contact_banner_head'>Let's <img src={contact_banner_img1} alt="" className='contact_banner_img'/> work</h1>
            <h1 className='contact_banner_head'><img src={contact_banner_img2} alt="" className='contact_banner_img2'/> together</h1>
            {/* <img src={contact_bar} alt="" className='contact_bar2' /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUsBanner