import React, { useState } from 'react'
// import contact_bar from "../../assets/images/contact_bar.png";
import about_purpose_image from "../../assets/images/about_purpose_image.png";
import eagle_image from "../../assets/images/eagle_image.png";
import transparency_icon from "../../assets/images/transparency_icon.png";
import wrk_life_icon from "../../assets/images/wrk_life_icon.png";
import diversity_icon from "../../assets/images/diversity_icon.png";
import recognition_icon from "../../assets/images/recognition_icon.png";
import creativity_icon from "../../assets/images/creativity_icon.png";
import connected_culture_icon from "../../assets/images/connected_culture_icon.png";
import psycologic_icon from "../../assets/images/psycologic_icon.png";
import purpose_icon from "../../assets/images/purpose_icon.png";
import mentorship_icon from "../../assets/images/mentorship_icon.png";
import expert_img from "../../assets/images/expert_img.png";
import expert_image2 from "../../assets/images/expert_image2.png";
import expert_image_3 from "../../assets/images/expert_image_3.png";
import expert_image_4 from "../../assets/images/expert_image_4.png";
import we_connected_back_img from "../../assets/images/we_connected_back_img.png";
import carosel_small from "../../assets/images/carosel_small.png";
import carosel_big from "../../assets/images/carosel_big.png";
import verticarosel_1 from "../../assets/images/verticarosel_1.png";

// 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const AboutUsContent = () => {
  const [MeetOurteam, SetMeetOurteam] = useState("Prime Experts")
  const team_tab = ["Prime Experts", "Experts Sessions", "Special Lectures"]
  const our_philosophy_array = [
    "Add Value",
    "Keep it Simple",
    "Think like a customer",
    "Trust people",
    "It is okay to make mistakes, but don’t repeat the same mistakes again",
    "Collaborate like an Army and Believe in Teamwork",
    "Take Pride for doing a Great Job",
    "Basics First and Specifics Later",
    "Be prepared for Continuous Learning",
    "Think on Paper",
    "Clients are Human, Understand their Emotions",
    "Don’t Assume, Just Ask", "Add Value",
    "Keep it Simple",
    "Think like a customer",
    "Trust people",
    "It is okay to make mistakes, but don’t repeat the same mistakes again",
    "Collaborate like an Army and Believe in Teamwork",
    "Take Pride for doing a Great Job",
    "Basics First and Specifics Later",
    "Be prepared for Continuous Learning",
    "Think on Paper",
    "Clients are Human, Understand their Emotions",
    "Don’t Assume, Just Ask"
  ];
  const our_philosophy = [...our_philosophy_array, ...our_philosophy_array]
  const Work_Culture_array = [
    "We are human only through the humanity of others",
    "We are people through other people",
    "We cannot be fully human alone",
    "We are human only through the humanity of others",
    "We are people through other people",
    "We cannot be fully human alone",
    "We are human only through the humanity of others",
    "We are people through other people",
    "We cannot be fully human alone",

  ];
  const Work_Culture = [...Work_Culture_array, ...Work_Culture_array]
  const images = [
    verticarosel_1,
    verticarosel_1,
    verticarosel_1,
    verticarosel_1,
    verticarosel_1,
    verticarosel_1,
    verticarosel_1,
    verticarosel_1
  ];
  const loopImages = [...images, ...images];

  const teamMembers = [
    {
      name: "John Doe",
      role: "Frontend Developer",
      img: expert_img
    },
    {
      name: "John Doe",
      role: "Frontend Developer",
      img: expert_img
    },
    {
      name: "John Doe",
      role: "Frontend Developer",
      img: expert_img
    },
    {
      name: "John Doe",
      role: "Frontend Developer",
      img: expert_img
    },
    // {
    //   name: "Jane Smith",
    //   role: "Backend Developer",
    //   img: expert_image2
    // },
    // {
    //   name: "Michael Brown",
    //   role: "UI/UX Designer",
    //   img: expert_image_3
    // },
    // {
    //   name: "Emily Davis",
    //   role: "Project Manager",
    //   img: expert_image_4
    // }
  ];
  const [hoveredIndex, setHoveredIndex] = useState(null);
  // const [isShowcard, setShowcard] = useState(false);

  return (
    <div className='overflow-hidden'>
      <div className='purposeof_se_parent_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='purpose_left' data-aos="fade-down-right">
                <h1>Purpose<br className='break_tag' />Of Social Eagle</h1>
              </div>

            </div>
            <div className='col-lg-6'>
              <div className='purpose_right'>
                <p data-aos="fade-down" data-aos-duration="2000">At our core, we strive to enrich lives by bringing unique value.</p>
                <p data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom" data-aos-duration="4000">Our aim is to elevate businesses by expanding their reach, delivering valuable<br className='break_tag' />
                  connections, and improving revenue streams. </p>
                <p data-aos="fade-down" data-aos-duration="6000">We're dedicated to ensuring that business owners find joy and success in their <br className='break_tag' />
                  business, creating a real impact in the lives the both of us touch</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-1'></div>
          <div className='col-lg-11 '>
            <div className='mt-5 position-relative'>
              <p className='purpose_img_top_content'>On A Mission To Add Value And Make A<br className='break_tag' />
                Difference In People Lives</p>
              <img src={about_purpose_image} alt="" className='purpose_image mt-2' />
              <p className='purpose_img_bottom_content'>Greater Reach + Quality Leads + Increased Sales = Happy Business Owner</p>
            </div>
          </div>
        </div>
      </div>
      <div className='eagle_img_parent_section'>
        <div className='container'>
          <div className='section_padding'>
            <div className='row'>
              <div className='col-lg-5'>
                <div>
                  <img src={eagle_image} alt="" className='eagle_image' />
                </div>
              </div>
              <div className='col-lg-7 my-auto'>
                <div className='eagle_section_right ps-lg-5 ps-2'>
                  <p data-aos="fade-down-right">These aren't just words; they're the fuel behind our success.<br className='break_tag' />
                    At <span className='text-danger'>Social Eagle</span>, we're on a mission to transform Ambition into<br className='break_tag' /> Achievement</p>
                  <p data-aos="fade-down-left">Dreams become reality through Determination, Leadership, and a<br className='break_tag' />
                    Competitive Spirit.<br className='break_tag' /> We don't settle for less than first place.</p>
                  <p data-aos="fade-down-right">Our pioneering digital transformation embraces fresh methodologies, <br className='break_tag' />ensuring excellence isn't an option; it's our standard.</p>
                  <p className='text-danger pb-0' data-aos="fade-down-left">Challenges?</p>
                  <p className='text-danger' data-aos="fade-down-right">They trigger miracles.</p>
                  <p data-aos="fade-down-left">We thrive in the age of Creative Minds, where change leads to <br className='break_tag' />opportunity. </p>
                  <p data-aos="fade-down-right">
                    We're committed to ethical practices, ensuring our work reflects our<br className='break_tag' /> values and principles.<br className='break_tag' />
                    We thrive on Innovation and Creativity with an eye for Excellence.</p>
                  <p data-aos="fade-down-left">Through transformative Leadership, we positively contribute to Faith and <br className='break_tag' />Trust, Hope and Competitiveness.<br className='break_tag' />
                    In our <span className='text-danger'>#EagleSpirit</span>, we achieve our Ambitious Goals through a Scientific <br className='break_tag' />approach through Technology by taking Initiatives and Daily Action.</p>
                  <p data-aos="fade-down-right">Join the Journey towards Excellence. Let's raise the standards together.</p>
                  <div className='d-flex'>
                    <button
                      type="button"
                      className="button_red d-flex mt-4"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <span >Fly With Us</span>
                      <div className='button_red_arrow'> <i class="fa-solid fa-arrow-right contact_arrow  ms-2"></i></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='philosophy_parent_section section_padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='purpose_left'>
                <h1 data-aos="zoom-in" data-aos-duration="2000">Our Philosophy</h1>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='purpose_right text-start'>
                <p className='pb-0' data-aos="zoom-out-down" data-aos-duration="2000">A philosophy that supports collectivism over individualism.</p>
                <p data-aos="zoom-out-up" data-aos-duration="2400">Ubuntu asserts that society gives human beings their humanity.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='our_philosophy_ubuntu'>
        <div className='container'>
          <p>'I Am Because We Are': The African Philosophy of Ubuntu</p>
        </div>
      </div>
      <div className='vertical_corosel_parent_section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12'>
              <div class="vertical_marque blur">
                <h4 className='text-center'>‘ONE FOR ALL’ AND ‘ALL FOR ONE’ </h4>
                <ul class="slider">
                  {our_philosophy.map((item, index) => (
                    <li><p>{item} </p></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='meetour_team_parent_section section_padding'>
        <div className='container'>
          <div className='d-flex justify-content-between'>
            <div>
              <h1 className='text-white'>Meet our team</h1>
            </div>
            <div className='d-flex meetour_team_tab'>
              {team_tab.map((item, index) => (<p onClick={() => SetMeetOurteam(item)} className={MeetOurteam === item ? "tab_active px-4" : "px-4"}>{item}</p>))}
            </div>
          </div>
          <div>
            <h4 className='text-center mt-5 meet_our_team_head' >Prime Experts</h4>
            <div className='d-flex justify-content-between'>
              {teamMembers?.map((item, index) => (
                <div className="d-flex" key={index}>
                  <div
                    className={`expert_card_parent position-relative ${hoveredIndex === index ? 'show' : 'hide'
                      }`}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <div className="expert_card_child">
                      <h6>{item?.name}</h6>
                      <p>{item?.role}</p>
                      <img src={item?.img} alt="Expert" className="expert_img_team" />
                    </div>
                  </div>
                  <div
                    className="expert_img_only"
                    onMouseEnter={() => setHoveredIndex(index)}
                  >
                    {hoveredIndex !== index && <img src={item?.img} alt="Expert" />}
                  </div>
                </div>
              ))}
            </div>
            
          </div>
        </div>
      </div>
      <div className='vertical_corosel_parent_section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12'>
              <div class="vertical_marque blur">
                <h4 className='text-center'>WORK CULTURE</h4>
                <ul class="slider2">
                  {Work_Culture.map((item, index) => (
                    <li><p>{item} </p></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="carosel_parent_section pb-5">
        <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {/* First Carousel Item */}
            <div className="carousel-item active" data-bs-interval="2000"> {/* Autoplays for 5 seconds */}
              <div className="row">
                <div className="col-lg-3 d-flex flex-column">
                  <img src={carosel_small} className="d-block w-100 carosel_image_hrsmall" alt="..." />
                  <img src={carosel_small} className="d-block w-100 carosel_image_hrsmall mt-5" alt="..." />
                </div>
                <div className="col-lg-6 d-flex justify-content-center align-item-center">
                  <img src={carosel_big} className="d-block w-100 carosel_image_hrbig" alt="..." />
                </div>
                <div className="col-lg-3 d-flex flex-column">
                  <img src={carosel_small} className="d-block w-100 carosel_image_hrsmall" alt="..." />
                  <img src={carosel_small} className="d-block w-100 carosel_image_hrsmall mt-5" alt="..." />
                </div>
              </div>
            </div>

            {/* Second Carousel Item */}
            <div className="carousel-item" data-bs-interval="2000"> {/* Autoplays for 5 seconds */}
              <div className="row">
                <div className="col-lg-3 d-flex flex-column">
                  <img src={carosel_small} className="d-block w-100 carosel_image_hrsmall" alt="..." />
                  <img src={carosel_small} className="d-block w-100 carosel_image_hrsmall mt-5" alt="..." />
                </div>
                <div className="col-lg-6 d-flex justify-content-center align-item-center">
                  <img src={carosel_big} className="d-block w-100 carosel_image_hrbig" alt="..." />
                </div>
                <div className="col-lg-3 d-flex flex-column">
                  <img src={carosel_small} className="d-block w-100 carosel_image_hrsmall" alt="..." />
                  <img src={carosel_small} className="d-block w-100 carosel_image_hrsmall mt-5" alt="..." />
                </div>
              </div>
            </div>
          </div>

          {/* Carousel Controls */}
          {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button> */}
        </div>
      </div>
      <div className='we_connted_section position-relative' data-aos="zoom-in" data-aos-duration="1000">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='we_connect_div'>
              <img src={we_connected_back_img} alt="" className='we_connect_backimg' />
              <p className='we_connect_para' data-aos="fade-down"
                data-aos-duration="3000">We are all connected and one can only grow and progress<br className='break_tag' /> through the growth and progression of others</p>
            </div>
          </div>
        </div>

      </div>
      <div className='values_section_parent_div section_padding'>
        <div className='container'>
          {/* <div className='section_padding_bottom'>
            <div className='row'>
              <div className='col-lg-6'>
                <h2 className='headings_text_white mb-md-3'>Value Creation Enabled  <br className='break_tag' />By Digital Transformation</h2>
              </div>
              <div className='col-lg-5'>
                <p className='text-white'>We believe in "Transforming Marketing Through Innovation and Technology" to deliver measurable value. Digital transformation goes beyond adopting new tools; it’s about creating value, solving problems, and meeting the dynamic needs of customers. Here’s how we help you achieve that</p>
              </div>
            </div>
          </div> */}
          <div className='row justify-content-between'>
            <div className='col-lg-3'>
              <div className='our_value_child_div mt-5'>
                <div className='icon_div' data-aos="fade-down" data-aos-duration="1000" data-aos-delay="0">
                  <img src={transparency_icon} alt=".." className='our_value_icon' />
                </div>
                <div className='our_value_content'>
                  <h6 data-aos="fade-right">Transparency</h6>
                  <p className='text-white' data-aos="fade-left">We value open communication. Through regular updates, team meetings, and newsletters, we keep everyone in the loop and build trust.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='our_value_child_div mt-5'>
                <div data-aos="fade-down" data-aos-duration="1700" data-aos-delay="400">
                  <img src={wrk_life_icon} alt=".." className='our_value_icon' />
                </div>
                <div className='our_value_content'>
                  <h6 data-aos="fade-right">Work-Life Balance</h6>
                  <p className='text-white' data-aos="fade-left">We prioritize our team's well-being, encouraging a balance that keeps our creative minds refreshed and focused.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='our_value_child_div mt-5'>
                <div data-aos="fade-down" data-aos-duration="2200" data-aos-delay="800" >
                  <img src={diversity_icon} alt=".." className='our_value_icon' />
                </div>
                <div className='our_value_content'>
                  <h6 data-aos="fade-right">Diversity and Inclusion</h6>
                  <p className='text-white' data-aos="fade-left">Different perspectives fuel our creativity. We embrace diversity to drive innovation in our digital strategies.</p>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className='row justify-content-between'>
            <div className='col-lg-3'>
              <div className='our_value_child_div value_margintop'>
                <div data-aos="fade-down" data-aos-duration="1000" data-aos-delay="0">
                  <img src={recognition_icon} alt=".." className='our_value_icon' />
                </div>
                <div className='our_value_content'>
                  <h6 data-aos="fade-down-right">Recognition and Appreciation</h6>
                  <p className='text-white' data-aos="fade-down-left">We celebrate our team's wins, big and small, to keep motivation high and spirits up.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='our_value_child_div value_margintop'>
                <div data-aos="fade-down" data-aos-duration="1500" data-aos-delay="400">
                  <img src={creativity_icon} alt=".." className='our_value_icon' />
                </div>
                <div className='our_value_content'>
                  <h6 data-aos="fade-down-right">Creativity and Innovation</h6>
                  <p className='text-white' data-aos="fade-down-left">We foster an environment where new ideas thrive, helping us stay ahead in the digital marketing world.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='our_value_child_div value_margintop'>
                <div data-aos="fade-down" data-aos-duration="2000" data-aos-delay="800">
                  <img src={connected_culture_icon} alt=".." className='our_value_icon' />
                </div>
                <div className='our_value_content'>
                  <h6 data-aos="fade-down-right">Connected Culture</h6>
                  <p className='text-white' data-aos="fade-down-left">We believe in staying connected. Team-building activities and collaboration help us grow together.</p>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className='row justify-content-between'>
            <div className='col-lg-3'>
              <div className='our_value_child_div value_margintop'>
                <div data-aos="fade-down" data-aos-duration="1000" data-aos-delay="0">
                  <img src={psycologic_icon} alt=".." className='our_value_icon' />
                </div>
                <div className='our_value_content'>
                  <h6 data-aos="fade-right">Psychological Safety</h6>
                  <p className='text-white' data-aos="fade-left">We encourage our team to share ideas freely, knowing their input is valued and respected.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='our_value_child_div value_margintop'>
                <div data-aos="fade-down" data-aos-duration="1500" data-aos-delay="400">
                  <img src={purpose_icon} alt=".." className='our_value_icon' />
                </div>
                <div className='our_value_content'>
                  <h6 data-aos="fade-right">Purpose-Driven Work</h6>
                  <p className='text-white' data-aos="fade-left">Our work goes beyond marketing. We aim to make a positive impact, with a focus on meaningful, sustainable goals.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='our_value_child_div value_margintop'>
                <div data-aos="fade-down" data-aos-duration="1500" data-aos-delay="800">
                  <img src={mentorship_icon} alt=".." className='our_value_icon' />
                </div>
                <div className='our_value_content'>
                  <h6 data-aos="fade-right">Mentorship and Growth</h6>
                  <p className='text-white' data-aos="fade-left">We support our team's growth through mentoring, providing guidance, and inspiring success.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='company_vibe_parent_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='company_vibes_left section_padding'>
                <h2 className='heading_para' data-aos="zoom-in" data-aos-duration="3000">Company Vibe</h2>
                <div className='my-4'>
                  <p>We believe that,</p>
                  <ul>
                    <li data-aos="fade-down-right"><p>When skilled individuals work together, they boost each other's <br className='break_tag' />abilities.</p> </li>
                    <li data-aos="fade-down-left"> <p>Success is like a forest fire.</p> </li>
                    <li data-aos="fade-down-right"> <p>We value a culture where everyone has the freedom to express<br className='break_tag' /> themselves and takes responsibility for their actions.</p> </li>
                    <li data-aos="fade-down-left"> <p>We're honest about our thoughts and strive to help one another.</p> </li>
                    <li data-aos="fade-down-right"> <p>Instead of enforcing rules, we guide based on understanding the  <br className='break_tag' />situation.</p> </li>
                  </ul>
                </div>
                <div className='d-flex '>
                  <button
                    type="button"
                    className="button_red d-flex mt-4"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <span >Fly With Us</span>
                    <div className='button_red_arrow'> <i class="fa-solid fa-arrow-right contact_arrow  ms-2"></i></div>
                  </button>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className="vertical-carousel-container">
                {/* Top Blur */}
                <div className="blur-top"></div>

                {/* Column 1: Top to Bottom */}
                <div className="carousel-column top-to-bottom">
                  {loopImages.map((image, index) => (
                    <img key={`col1-${index}`} src={image} alt={`Image ${index + 1}`} />
                  ))}
                </div>

                {/* Column 2: Bottom to Top */}
                <div className="carousel-column bottom-to-top">
                  {loopImages.map((image, index) => (
                    <img key={`col2-${index}`} src={image} alt={`Image ${index + 1}`} />
                  ))}
                </div>

                {/* Column 3: Top to Bottom */}
                <div className="carousel-column top-to-bottom">
                  {loopImages.map((image, index) => (
                    <img key={`col3-${index}`} src={image} alt={`Image ${index + 1}`} />
                  ))}
                </div>

                {/* Bottom Blur */}
                <div className="blur-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default AboutUsContent