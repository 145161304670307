import React from 'react'
import images from "../../../../helpers/images"

const ContentSection = () => {
    return (
        <div className='Agency_detail_page'>
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='purpose_left'>
                                <h1> Video Content  <br className='break_tag' />Production</h1>
                                <button className='button_red mt-4 agency_into_button'>Consult Now</button>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='purpose_right pe-5'>
                                <p>Looking to captivate your audience and bring your ideas to life? Our Video Content Production services are designed to help you tell your story in a way that resonates, drives engagement, and leaves a lasting impact.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='why_digital_market_left'>
                                <h2 className='headings_text_white'>Why Video Content? </h2>
                                <img src={images.Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-4' />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='why_digital_market_right'>
                                <p className='paraWhite_margin_top'>Video is one of the most powerful mediums in today’s digital landscape, allowing brands to connect with audiences on an emotional level. Engaging, high-quality video content not only captures attention but also improves brand recall, boosts engagement, and drives conversions.</p>
                                <button className='button_white mt-4'> Get Consultation Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='Agency_added_section'>
                <div className='container'>
                    <div className='Agency_added_section_heading section_padding'>
                        <h1 className='heading_para text-center'>How Video Content Benefits<br className='break_tag' /> Your Business</h1>
                    </div>
                    <div className='section_padding_bottom'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex'>
                                    <div><p className='number_list_backred'>1</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Enhanced Audience Engagement</h4>
                                        <p>Video content is more likely to capture attention, keep viewers engaged, and encourage interaction.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex me-0'>
                                    <div><p className='number_list_backred'>2</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Improved Brand Recall</h4>
                                        <p> High-quality videos make your brand memorable, ensuring viewers think of you when they’re ready to make a decisiond.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection d-flex'>
                                    <div><p className='number_list_backred'>3</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Increased Conversions</h4>
                                        <p> Videos that tell your brand’s story and highlight its value drive conversions by providing clear, compelling reasons to choose your business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection  d-flex me-0'>
                                    <div><p className='number_list_backred'>4</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Greater Reach on Social Media</h4>
                                        <p>Video content performs exceptionally well on social platforms, amplifying your message to a broader audience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing'>
                <div className='row'>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }}>
                            <div className='container'>
                                <h2 className='headings_text_white'>Why Choose Our Video <br className='break_tag' />   Content Production  <br className='break_tag' />  Services? </h2>
                                <p className='paraWhite_margin_top mb-5'>we make your vision come alive. From concept development to post-production, our team handles every step of the process, ensuring your message is clear, compelling, and impactful.</p>
                                <img src={images.social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                                <p className='paraWhite_margin_top mt-5'>With our video content production expertise, we bring your brand’s story to life, helping you connect, engage, and convert.</p>
                                <h2 className='headings_text_white mt-5'>Ready to make an<br className='break_tag' /> unforgettable impression?</h2>
                                <button className='button_red mt-5'>  Get Your FREE Video Strategy Session</button>
                            </div>  
                        </div>
                    </div>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_rght'>
                            <h3 className='headings_text_white mb-5'>Here’s what our video content  <br className='break_tag' /> production offers</h3>
                            <div className='agency_detail_whyChoose_rght_content mt-0'>
                                <div>
                                    <img src={images.video_content_icon1} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Concept Development</h5>
                                    <p> We work with you to brainstorm and develop ideas that align with your brand’s goals, crafting a compelling story that captures your message and engages viewers from the start.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.video_content_icon2} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Professional Filming & Editing</h5>
                                    <p> Our team handles the entire production process—from filming with high-quality equipment to precise editing—ensuring each video reflects your brand’s unique style and standards.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.video_content_icon3} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Engaging Storytelling</h5>
                                    <p> We create videos that not only inform but also tell a story. Whether it’s a promotional ad, tutorial, or brand documentary, our storytelling approach connects with audiences on a deeper level.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content '>
                                <div>
                                    <img src={images.video_content_icon4} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>High-Quality Content Production</h5>
                                    <p>  We specialize in producing sharp, visually appealing content that stands out, increasing your brand’s visibility and credibility.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content '>
                                <div>
                                    <img src={images.video_content_icon5} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Performance-Driven Strategy</h5>
                                    <p>  Each video is crafted with purpose, and we track performance metrics to optimize and refine future content for even better results.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContentSection