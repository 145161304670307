import React, { useEffect, useRef, useState } from 'react'
import why_digital_market from "../../assets/images/why-digital_market.png";
import business_goals from "../../assets/images/business_goals.png";
import { branding_designing_content } from "../../helpers/contents/AgencyBrandingDesigning"
import { digital_marketing_content } from "../../helpers/contents/AgencyDigitalMarketing"



const AgencyContent = () => {
  const [agencyTabs, setAgencyTabs] = useState(0)
  const [digitalMarketingContent, setDigitalMarketingContent] = useState(0)
  const [BrandingDesignContent, setDigitalBrandingContent] = useState(0)
  const Agency_tabs = ["Digital Marketing", "Branding & Designing"]
  const digital_marketing_detail_page = [
    { heading: "Social Media Management", redirectLink: "/agency/social-media-management" },
    { heading: "Social Media Marketing", redirectLink: "/agency/social-media-marketing" },
    { heading: "Lead Generation", redirectLink: null },
    { heading: "Pay-Per-Click", redirectLink: "/agency/pay-per-click" },
    { heading: "Performance Marketing", redirectLink: "/agency/performance-marketing" },
    { heading: "Digital Media Planning & Buying", redirectLink: "/agency/digital-media-planning-and-buying" },
    { heading: "SEO Services", redirectLink: "/agency/search-engine-optimization" },
    { heading: "Content Marketing", redirectLink: "/agency/content-marketing-service" },
    { heading: "Email marketing", redirectLink: "/agency/email-marketing-service" },
    { heading: "Automation", redirectLink: "/agency/marketing-automation" }
  ];
  const branding_designing_detail_page = [
    { heading: "Creative Solution", redirectLink: "/agency/creative-solutions" },
    { heading: "Video Content Production", redirectLink: "/agency/video-content-production" },
    { heading: "Video Marketing", redirectLink: "/agency/video-marketing" },
    { heading: "Funnel Building & Marketing", redirectLink: "/agency/funnel-building-marketing" }
  ];

  const [currentDigitalMarketingContent, setCurrentDigitalMarketingContent] = useState(digital_marketing_content[digitalMarketingContent])
  const [currentBrandingDesignContent, setCurrentBrandingDesignContent] = useState(branding_designing_content[BrandingDesignContent])
  useEffect(() => {
    setCurrentDigitalMarketingContent(digital_marketing_content[digitalMarketingContent])
    setCurrentBrandingDesignContent(branding_designing_content[BrandingDesignContent])
  }, [digitalMarketingContent, BrandingDesignContent])

  const sectionRef = useRef(null);
  const [isSectionScrollable, setIsSectionScrollable] = useState(false);
  const navbarHeight = 150;
  const [isInNextSection, setIsInNextSection] = useState(false);

  const handleScroll = () => {
    const section = sectionRef.current;
    if (section) {
      const sectionTop = section.getBoundingClientRect().top;
      if (sectionTop <= navbarHeight) {
        setIsSectionScrollable(true);
      } else {
        setIsSectionScrollable(false);
      }
      if (sectionTop < -section.offsetHeight) {
        if (!isInNextSection) {
          setIsInNextSection(true);
          setDigitalMarketingContent(0);
        }
      } else if (sectionTop >= 0) {
        if (isInNextSection) {
          setIsInNextSection(false);
          setDigitalMarketingContent(0);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isInNextSection]);

  // const handleSectionScroll = () => {
  //   if (!isSectionScrollable) return;
  //   const section = sectionRef.current;
  //   if (section) {
  //     const scrollTop = section.scrollTop;
  //     const leftColumn = section.querySelector(".agency_detail_listing_left");
  //     const rightColumn = section.querySelector(".agency_detail_listing_right");
  //     if (leftColumn) leftColumn.scrollTop = scrollTop;
  //     if (rightColumn) rightColumn.scrollTop = scrollTop;
  //   }
  // };

  // 
  const [isSticky, setIsSticky] = useState(false);
  const [originalOffset, setOriginalOffset] = useState(0);
  const [showTabs, setShowTabs] = useState(true)
  const lastSectionRef = useRef(null);
  useEffect(() => {
    const tabs = document.querySelector(".agency_tabsection");
    const navbar = document.querySelector(".navbar");
    setOriginalOffset(tabs.offsetTop);
    const handleScroll = () => {
      const navbarHeight = navbar?.offsetHeight || 0;
      const currentScrollY = window.scrollY;

      if (currentScrollY > originalOffset - navbarHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      if (lastSectionRef.current) {
        const lastSectionBottom =
          lastSectionRef.current.getBoundingClientRect().bottom +
          window.scrollY;
        const windowBottom = currentScrollY + window.innerHeight;

        if (windowBottom >= lastSectionBottom) {
          setShowTabs(false);
        } else {
          setShowTabs(true);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [originalOffset]);

  return (
    <div className='overflow-hidden'>
      <div className='agency_tabsection'>
        {showTabs && (
          <div className='d-flex justify-content-center'>
            <div className={`technology_tabs ${isSticky ? "sticky first_technology_tab" : ""} d-flex px-2`} style={isSticky ? { top: "60px", width: "fit-content" } : { width: "fit-content" }}>
              {Agency_tabs.map((item, index) => (
                <p className={agencyTabs === index ? "techno_tab_active " : "techno_tab_link"}
                  key={index} onClick={() => {
                    setAgencyTabs(index);
                    // handleClickScroll(index)
                  }}>{item}</p>
              ))}
            </div>
          </div>
        )}
        {/* Digital Marketing */}
        {agencyTabs === 0 && (
          <>
            <div className={` ${isSticky ? "first_technology_tab" : ""}`}>
              <div className='container ' >
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='purpose_left'>
                      <h1 data-aos="fade-up">Digital  <br className='break_tag' />Marketing</h1>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='purpose_right mt-4 section_padding_bottom'>
                      <p data-aos="fade-down">Digital marketing, or online marketing, is using websites, apps, social media, search engines, and other online tools to promote a business, sell products, boost brand awareness, or reach other key business goals.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='why_digital_market_section section_padding'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='why_digital_market_left d-flex justify-content-center'>
                      <img src={why_digital_market} alt="../" className='why_digital_market_image ' data-aos="zoom-in"/>
                    </div>
                  </div>
                  <div className='col-lg-6' >
                    <div className='why_digital_market_right'>
                      <h2 className='headings_text_white' data-aos="fade-down-left">Why Does Digital Marketing Matters for Your Business?</h2>
                      <p className='text-white mt-3' data-aos="fade-down-right">Digital marketing is important because it helps businesses connect with people online. Here’s why it matters:</p>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='d-flex why_digital_market_list'>
                            <p className='number_list' data-aos="fade-down" data-aos-duration="1000" data-aos-delay="0">1</p>
                            <p className='tex-white my-auto why_digital_market_para ms-2' data-aos="fade-right">Reach More People</p>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='d-flex why_digital_market_list'>
                            <p className='number_list' data-aos="fade-down" data-aos-duration="1200" data-aos-delay="100">2</p>
                            <p className='tex-white my-auto why_digital_market_para ms-2' data-aos="fade-left">Save Money</p>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='d-flex why_digital_market_list'>
                            <p className='number_list' data-aos="fade-down" data-aos-duration="1300" data-aos-delay="200">3</p>
                            <p className='tex-white my-auto why_digital_market_para ms-2' data-aos="fade-right">Target the Right Audience</p>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='d-flex why_digital_market_list'>
                            <p className='number_list' data-aos="fade-down" data-aos-duration="1400" data-aos-delay="300">4</p>
                            <p className='tex-white my-auto why_digital_market_para ms-2' data-aos="fade-left">Track Results</p>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='d-flex why_digital_market_list'>
                            <p className='number_list' data-aos="fade-down" data-aos-duration="1500" data-aos-delay="400">5</p>
                            <p className='tex-white my-auto why_digital_market_para ms-2' data-aos="fade-right">Build Relationships</p>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='d-flex why_digital_market_list'>
                            <p className='number_list' data-aos="fade-down" data-aos-duration="1600" data-aos-delay="500">6</p>
                            <p className='tex-white my-auto why_digital_market_para ms-2' data-aos="fade-left">Stay Up-to-Date</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="agency_detail_listing"
              ref={sectionRef}
              // onScroll={handleSectionScroll}
              style={{
                overflowY: isSectionScrollable ? "auto" : "hidden",
                maxHeight: "calc(100vh - 100px)",
              }}>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='agency_detail_listing_left w-100'>
                    {digital_marketing_detail_page?.map((item, index) => (
                      <div className={`${index === digital_marketing_detail_page.length - 1 ? "details_pages_para_last"
                        : "details_pages_para"} d-flex justify-content-between`}
                        onClick={() => {
                          setDigitalMarketingContent(index)
                        }}
                        onMouseOver={() => {
                          setDigitalMarketingContent(index);
                        }}
                      // onMouseLeave={() => {
                      //   setDigitalMarketingContent(0); // Reset content to index 0 when mouse leaves
                      // }}
                      >
                        <p key={index} className={`${index === digitalMarketingContent ? "page_active" : ""}`}>{item?.heading}</p>
                        <a href={item?.redirectLink} style={{ textDecoration: "none" }}>
                          <i className={`${index === digitalMarketingContent ? "circle_arrow_active" : ""} fa-solid fa-arrow-right circle_arrow my-auto`}></i>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='col-lg-6'>
                  {/* <div className='container'> */}
                  <div className='agency_detail_listing_right'>
                    <h2 className='heading_para' data-aos="zoom-in">{currentDigitalMarketingContent?.heading}</h2>
                    <p className='mt-3' data-aos="fade-up">{currentDigitalMarketingContent?.SubPara}</p>
                    <ul>
                      {currentDigitalMarketingContent?.ListPara.map((item, index) => (
                        <li className='mt-3' data-aos="fade-down">{item}</li>
                      ))}
                    </ul>
                    {currentDigitalMarketingContent?.Content.map((item, index) => (
                      <p className='mt-3' data-aos="fade-up">{item}</p>
                    ))}
                    <a href={currentDigitalMarketingContent?.RedirectLink}>
                      <button className='button_red mt-5' data-aos="zoom-out">Learn More <i class="fa-solid fa-arrow-right ms-2"></i></button>
                    </a>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div ref={lastSectionRef}></div>
          </>
        )}

        {/* Branding and Designing */}
        {agencyTabs === 1 && (
          <>
            <div className={` ${isSticky ? "first_technology_tab" : ""}`}>
              <div className='container'>
                <div className='purpose_left section_padding_bottom'>
                  <h1 className='text-center' data-aos="zoom-in">Branding & Designing</h1>
                </div>
              </div>
            </div>
            <div className='why_digital_market_section'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='why_digital_market_left section_padding_top'>
                      <h2 className='headings_text_white' data-aos="fade-up">Transform your brand with <br className='break_tag' />our expert Branding & <br className='break_tag' />Designing services!</h2>
                      <img src={business_goals} alt="../" className='why_digital_market_image why_digital_market_image_responsive mt-4' data-aos="fade-down"/>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='why_digital_market_right section_padding'>
                      <p className='text-white mt-4 mt-md-0' data-aos="zoom-out-left"><b>Brand Strategy Development: </b> We’ll work with you to clarify your brand’s vision, mission, and values, setting the stage for a powerful identity. </p>
                      <p className='text-white mt-4' data-aos="zoom-out-right"><b>Visual Identity Creation: </b> Our designers will create logos and visual elements that truly reflect who you are and connect with your audience. </p>
                      <p className='text-white mt-4' data-aos="zoom-out-left"><b>Marketing Collateral Design: </b> From business cards to brochures, we design stunning materials that keep your branding consistent and engaging.                      </p>
                      <p className='text-white mt-4' data-aos="zoom-out-right"><b>Website and UI/UX Design: </b> We build beautiful, user-friendly websites that enhance user experience and boost conversions.                      </p>
                      <p className='text-white mt-4' data-aos="zoom-out-left">Let’s create a memorable brand identity together! Your brand deserves to make an impact.                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='agency_detail_listing2'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='branding_design_listing_left mx-auto'>
                    {branding_designing_detail_page?.map((item, index) => (
                      <div className={`${index === branding_designing_detail_page.length - 1 ? "details_pages_para_last"
                        : "details_pages_para"} d-flex justify-content-between`}
                        onClick={() => {
                          setDigitalBrandingContent(index)
                        }}
                        onMouseOver={() => {
                          setDigitalBrandingContent(index);
                        }}
                      // onMouseLeave={() => {
                      //   setDigitalBrandingContent(0);
                      // }}
                      >
                        <p key={index} className={`${index === BrandingDesignContent ? "page_active" : ""}`}>{item?.heading}</p>
                        <a href={item?.redirectLink} style={{ textDecoration: "none" }}>
                          <i className={`${index === BrandingDesignContent ? "circle_arrow_active" : ""} fa-solid fa-arrow-right circle_arrow my-auto`}></i>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='agency_detail_listing_right overflow-hidden'>
                    <h2 className='heading_para' data-aos="zoom-in">{currentBrandingDesignContent?.heading}</h2>
                    {currentBrandingDesignContent?.content?.map((item, index) => (
                      <p className='mt-4' data-aos="fade-up-right">{item}</p>
                    ))}
                    <a href={currentBrandingDesignContent?.RedirectLink}>
                      <button className='button_red mt-5'  data-aos="fade-up">Learn More <i class="fa-solid fa-arrow-right ms-2"></i></button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>)}
        <div ref={lastSectionRef}></div>
      </div>
    </div >
  )
}

export default AgencyContent