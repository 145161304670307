import React from 'react'
import images from "../../../../helpers/images"

const ContentSection = () => {
    return (
        <div className='Agency_detail_page'>
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='purpose_left'>
                                <h1> Video  <br className='break_tag' /> Marketing  </h1>
                                <button className='button_red mt-4 agency_into_button'>Consult Now</button>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='purpose_right pe-5'>
                                <p>Ready to amplify your brand’s impact? Our Video Marketing services are crafted to engage your audience, elevate your message, and drive measurable results.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='why_digital_market_left'>
                                <h2 className='headings_text_white'>Why Video Marketing? </h2>
                                <img src={images.Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-4' />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='why_digital_market_right'>
                                <p className='paraWhite_margin_top'>Absolutely! Video marketing is one of the most powerful tools for connecting with audiences and building brand awareness. With video, you can tell your brand’s story, demonstrate products, and create memorable experiences that stay with your viewers. Engaging video content not only boosts visibility but also increases engagement, conversions, and customer loyalty.</p>
                                <button className='button_white mt-4'> Get Consultation Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='Agency_added_section'>
                <div className='container'>
                    <div className='Agency_added_section_heading section_padding'>
                        <h1 className='heading_para text-center'>Benefits of Video Marketing<br className='break_tag' /> for Your Business</h1>
                    </div>
                    <div className='section_padding_bottom'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex'>
                                    <div><p className='number_list_backred'>1</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Greater Audience Engagement</h4>
                                        <p> Video content grabs attention and keeps viewers engaged, encouraging interaction and boosting brand recall.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex me-0'>
                                    <div><p className='number_list_backred'>2</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Improved Conversion Rates</h4>
                                        <p> Compelling videos provide clear reasons for customers to choose your brand, driving conversions and sales.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection d-flex'>
                                    <div><p className='number_list_backred'>3</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Expanded Social Reach</h4>
                                        <p>  Videos are highly shareable, amplifying your message and reaching new audiences across social platforms.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection  d-flex me-0'>
                                    <div><p className='number_list_backred'>4</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Increased Brand Trust</h4>
                                        <p> High-quality videos convey professionalism, building trust and credibility with your audience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing'>
                <div className='row'>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }}>
                            <div className='container'>
                                <h2 className='headings_text_white'>Why Choose Our Video <br className='break_tag' />   Marketing Services?</h2>
                                <p className='paraWhite_margin_top mb-5'>We drive real results through tailored video strategies. From concept to distribution, we handle every aspect, ensuring that your videos are optimized for each platform and connect meaningfully with your audience.</p>
                                <img src={images.social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                                <p className='paraWhite_margin_top mt-5'>With our video marketing expertise, we help you connect authentically with your audience, expanding your reach and achieving your goals.</p>
                                <h2 className='headings_text_white mt-5'>Ready to boost your<br className='break_tag' /> brand with powerful<br className='break_tag' /> video marketing?</h2>
                                <button className='button_red mt-5 agency_details_button'> Get Your FREE Video Marketing Consultationn</button>
                            </div>  
                        </div>
                    </div>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_rght'>
                            <h3 className='headings_text_white mb-5'>Here’s how we bring video  <br className='break_tag' /> marketing to life</h3>
                            <div className='agency_detail_whyChoose_rght_content mt-0'>
                                <div>
                                    <img src={images.video_marketing_icon1} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Customized Video Campaigns</h5>
                                    <p> We start by understanding your brand’s goals and target audience, creating video campaigns that align with your unique objectives, whether it’s for product promotion, testimonials, or brand storytelling.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.video_marketing_icon2} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Platform Optimization</h5>
                                    <p> Each video is customized and optimized for maximum performance on platforms like YouTube, Instagram, Facebook, and LinkedIn, ensuring your content reaches a broad audience.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.video_marketing_icon3} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Engaging Content Creation</h5>
                                    <p> We specialize in creating dynamic, visually appealing content that captivates viewers, from short ads and educational videos to in-depth product demos and behind-the-scenes brand stories.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content mb-5'>
                                <div>
                                    <img src={images.video_marketing_icon4} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Analytics-Driven Approach</h5>
                                    <p>  We track video performance, analyzing engagement and conversion metrics to refine strategies, ensuring your video marketing efforts continuously deliver top results.</p>
                                </div>
                            </div>
                            <h3 className='headings_text_white mt-5'>How We Use Video Marketing <br className='break_tag' /> to Drive Success</h3>
                            <div className='agency_detail_whyChoose_rght_content '>
                                <div>
                                    <img src={images.video_marketing_icon5} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Product Promotions</h5>
                                    <p>  Showcase your products in a compelling way that highlights features and benefits, increasing interest and conversions.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content '>
                                <div>
                                    <img src={images.video_marketing_icon6} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Customer Testimonials</h5>
                                    <p>  Build trust and credibility with authentic testimonials from satisfied customers, enhancing your brand’s reputation.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content '>
                                <div>
                                    <img src={images.video_marketing_icon7} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Brand Awareness Campaigns</h5>
                                    <p>  Capture the essence of your brand and create videos that boost visibility, fostering a stronger connection with your audience.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content '>
                                <div>
                                    <img src={images.video_marketing_icon8} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Educational Content</h5>
                                    <p>  Provide valuable, informative content that educates your audience, positioning your brand as an expert in your industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection