import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Logo from "../../assets/images/logo.svg";
import white_logo from "../../assets/images/white_logo.svg";
import dummyicon1 from "../../assets/images/dummy_icon1.png";
import dummyicon2 from "../../assets/images/dummy_icon2.png";
import Facebook from "../../assets/images/Facebook.png";
import Instagram from "../../assets/images/Instagram.png";
import Linkedin from "../../assets/images/Linkedin.png";
import Twitter from "../../assets/images/Twitter.png";
import case_study from "../../assets/images/case_study.png";
import blogs from "../../assets/images/blogs.png";
import ROUTES from "../../helpers/constants/routes";


const Navbar = () => {
    const ServiceTabs = ["Agency", "Academy", "Advisory", "Technology Development"];
    const [selectedServiceTabs, setSelectedServiceTabs] = useState(
        ServiceTabs[0]
    );

    const workwithusTabs = ["Case Study", "Blogs", "Usefull Development"];
    const [selectedWorkWithUsTabs, setSelectedWorkWithUsTabs] = useState(
        workwithusTabs[0]
    );

    const [scrolled, setScrolled] = useState(false);
    let location = useLocation();
    console.log(location.pathname, "pathname")


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const navigate = useNavigate();
    return (
        <div>
            <div className={`bg-light-nav-top ${scrolled ? "bg-light-nav-top-scrolled" : ""}`}>
                <div className="home-navbar">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="container">
                            <NavLink className="navbar-brand d-flex align-items-end" to="/">
                                {['/', '/agency', '/technology-development'].some(path =>
                                    location.pathname.toLowerCase() === path || location.pathname.toLowerCase().startsWith('/agency/')
                                ) ? (
                                    <img className="logo-img" src={white_logo} alt="White Logo" />
                                ) : null}
                                {['/contact-us', '/advisory'].some(
                                    (path) =>
                                        location.pathname.toLowerCase() === path ||
                                        location.pathname.toLowerCase().startsWith('/advisory/')
                                ) ? (
                                    scrolled ? (
                                        <img className="logo-img" src={white_logo} alt="White Logo" />
                                    ) : (
                                        <img className="logo-img" src={Logo} alt="Default Logo" />
                                    )
                                ) : null}
                            </NavLink>
                            <button
                                className="navbar-toggler my-2"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#main_nav"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div
                                className="collapse navbar-collapse justify-content-end lh-lg"
                                id="main_nav"
                            >
                                <ul className="navbar-nav p-3 p-md-0 mx-auto">
                                    <li className="nav-item d-lg-block d-md-none d-none dropdown ktm-mega-menu">
                                        <a
                                            className={`nav-link  ${scrolled ? "nav-link-scrolled" : ""}`}
                                            href={ROUTES.ABOUTUS}
                                        >
                                            About Us
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown d-lg-block d-md-none d-none ktm-mega-menu">
                                        <a
                                            className={`nav-link dropdown-toggle ${scrolled ? "nav-link-scrolled" : ""}`}
                                            href={ROUTES.AGENCY}
                                            data-bs-toggle="dropdown"
                                        >
                                            Services
                                        </a>
                                        <div className="dropdown-menu mega-menu p-3">
                                            <div className="mega-menu-inner">
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div className="">
                                                            <div className="tabs border-b">
                                                                {ServiceTabs.map((tab, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={
                                                                                selectedServiceTabs === tab
                                                                                    ? "tab active  "
                                                                                    : "tab "
                                                                            }
                                                                            onMouseOver={() => {
                                                                                setSelectedServiceTabs(tab);
                                                                            }}
                                                                            onClick={() => {
                                                                                if (
                                                                                    selectedServiceTabs === "Advisory"
                                                                                ) {
                                                                                    navigate(`${ROUTES.ADVISORY}`);
                                                                                } else if (
                                                                                    selectedServiceTabs === "Academy"
                                                                                ) {
                                                                                    navigate("/Academy");
                                                                                } else if (
                                                                                    selectedServiceTabs === "Agency"
                                                                                ) {
                                                                                    navigate(`${ROUTES.AGENCY}`)
                                                                                }
                                                                                else if (
                                                                                    selectedServiceTabs === "Technology Development"
                                                                                ) {
                                                                                    navigate(`${ROUTES.TECHNOLOGYDEVELOPMENT}`);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                <img src={dummyicon1} alt="" />
                                                                            </span>
                                                                            <span className='my-auto ms-2'>{tab}</span>

                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="padd-left">
                                                            {selectedServiceTabs === "Agency" && (
                                                                <>

                                                                    <p className='text-white mb-0'>Digital Marketing</p>
                                                                    <div className="row">
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.SOCIAL_MEDIA_MANAGEMENT}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Social Media Management</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                            Lorem Ipsum is simply dummy text of the printing.
                                                                                            Lorem Ipsum is simply dummy text of the printing.
                                                                                            Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.SOCIAL_MEDIA_MARKETING}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Social Media Marketing</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Lead Generation</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.PAY_PER_CLICK}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>PayPerClick(PPC)</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.PERFORMANCE_MARKETING}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Performance Marketing</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.DIGITAL_MEDIA_PLANNING_AND_BUYING}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Digital Media Planning & Buying</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.SEARCH_ENGINE_OPTIMIZATION}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>SEO Services</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.CONTENT_MARKETING_SERVICE}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Content Marketing</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.EMAIL_MARKETING_SERVICE}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Email Marketing</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Automation</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>

                                                                        <hr className='text-white mt-4 mb-4' />
                                                                    </div>
                                                                    <p className='text-white mb-0'>Branding & Designing</p>
                                                                    <div className="row">
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.CREATIVE_SOLUTIONS}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Creative Solutions</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.VIDEO_CONTENT_PRODUCTION}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Video Content Production</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.VIDEO_MARKETING}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Video Marketing</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <NavLink
                                                                                to={`${ROUTES.AGENCY_SERVICES.FUNNEL_BUILDING_MARKETING}`}
                                                                                className="text-decoration-none"
                                                                            >
                                                                                <div className='d-flex navleft_link mt-3'>
                                                                                    <div>
                                                                                        <img className="parent_page_icon" src={dummyicon2} alt="" />
                                                                                    </div>
                                                                                    <div className='mt-2 ms-2'>
                                                                                        <h6 className='text-white mb-0'>Funnel Building & Marketing</h6>
                                                                                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                    </div>

                                                                </>
                                                            )}
                                                            {selectedServiceTabs === "Academy" && (
                                                                <NavLink
                                                                    to="/Academy"
                                                                    className="text-decoration-none"
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-lg-7">
                                                                            <div className='d-flex navleft_link mt-3'>
                                                                                <div>
                                                                                    <img src={dummyicon2} alt="" />
                                                                                </div>
                                                                                <div className='mt-2 ms-2'>
                                                                                    <h6 className='text-white mb-0'>Digital Entrepreneurship (Agency based training)</h6>
                                                                                    <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </NavLink>
                                                            )}
                                                            {selectedServiceTabs === "Advisory" && (

                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <NavLink
                                                                            to={`${ROUTES.ADVISORY_SERVICES.MARKETING_STRATEGY_CONSULTING}`}
                                                                            className="text-decoration-none"
                                                                        >
                                                                            <div className='d-flex navleft_link mt-3'>
                                                                                <div>
                                                                                    <img src={dummyicon2} alt="" />
                                                                                </div>
                                                                                <div className='mt-2 ms-2'>
                                                                                    <h6 className='text-white mb-0'>Marketing Strategy & Consulting</h6>
                                                                                    <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>

                                                                    <div className="col-lg-6">
                                                                        <NavLink
                                                                            to={`${ROUTES.ADVISORY_SERVICES.MARKETING_PROCESS_AUTOMATION}`}
                                                                            className="text-decoration-none"
                                                                        >
                                                                            <div className='d-flex navleft_link mt-3'>
                                                                                <div>
                                                                                    <img src={dummyicon2} alt="" />
                                                                                </div>
                                                                                <div className='mt-2 ms-2'>
                                                                                    <h6 className='text-white mb-0'>Marketing process & Automation</h6>
                                                                                    <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <NavLink
                                                                            to={`${ROUTES.ADVISORY_SERVICES.BRANDING_STRATEGY_CONSULTING}`}
                                                                            className="text-decoration-none"
                                                                        >
                                                                            <div className='d-flex navleft_link mt-3'>
                                                                                <div>
                                                                                    <img src={dummyicon2} alt="" />
                                                                                </div>
                                                                                <div className='mt-2 ms-2'>
                                                                                    <h6 className='text-white mb-0'>Brand Strategy & Consulting</h6>
                                                                                    <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <NavLink
                                                                            to={`${ROUTES.ADVISORY_SERVICES.COMMUNITY_MASTERMIND_CREATION}`}
                                                                            className="text-decoration-none"
                                                                        >
                                                                            <div className='d-flex navleft_link mt-3'>
                                                                                <div>
                                                                                    <img src={dummyicon2} alt="" />
                                                                                </div>
                                                                                <div className='mt-2 ms-2'>
                                                                                    <h6 className='text-white mb-0'>Community & Mastermind crteation</h6>
                                                                                    <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>

                                                            )}
                                                            {selectedServiceTabs === "Technology Development" && (
                                                                <NavLink
                                                                    to="/TechnologyPartner"
                                                                    className="text-decoration-none"
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className='d-flex navleft_link mt-3'>
                                                                                <div>
                                                                                    <img src={dummyicon2} alt="" />
                                                                                </div>
                                                                                <div className='mt-2 ms-2'>
                                                                                    <h6 className='text-white mb-0'>Web & Mobile App Development</h6>
                                                                                    <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className='d-flex navleft_link mt-3'>
                                                                                <div>
                                                                                    <img src={dummyicon2} alt="" />
                                                                                </div>
                                                                                <div className='mt-2 ms-2'>
                                                                                    <h6 className='text-white mb-0'>E-Com Custom Website Development</h6>
                                                                                    <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className='d-flex navleft_link mt-3'>
                                                                                <div>
                                                                                    <img src={dummyicon2} alt="" />
                                                                                </div>
                                                                                <div className='mt-2 ms-2'>
                                                                                    <h6 className='text-white mb-0'>Shopify Development</h6>
                                                                                    <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className='d-flex navleft_link mt-3'>
                                                                                <div>
                                                                                    <img src={dummyicon2} alt="" />
                                                                                </div>
                                                                                <div className='mt-2 ms-2'>
                                                                                    <h6 className='text-white mb-0'>WordPress Development</h6>
                                                                                    <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className='d-flex navleft_link mt-3'>
                                                                                <div>
                                                                                    <img src={dummyicon2} alt="" />
                                                                                </div>
                                                                                <div className='mt-2 ms-2'>
                                                                                    <h6 className='text-white mb-0'>Web & Mobile App Maintenance</h6>
                                                                                    <p>Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.
                                                                                        Lorem Ipsum is simply dummy text of the printing.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </NavLink>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between navbar_footer'>
                                                        <div className='d-flex'>
                                                            <div className='me-5'> <i class="fa-regular fa-envelope text-white me-2"></i>
                                                                <a href="mailto:flyhigh@socialeagle.in" className='mails_tags'>flyhigh@socialeagle.in</a>
                                                            </div>
                                                            <div> <i class="fa-solid fa-phone text-white me-2"></i>
                                                                <a href="mailto:+91 8428421222" className='mails_tags'>+91 8428421222</a>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='me-2 social_media'>
                                                                <img src={Facebook} alt="" className='social_media_img' />
                                                            </div>
                                                            <div className='me-2 social_media'>
                                                                <img src={Instagram} alt="" className='social_media_img' />
                                                            </div>
                                                            <div className='me-2 social_media'>
                                                                <img src={Twitter} alt="" className='social_media_img' />
                                                            </div>
                                                            <div>
                                                                <img src={Linkedin} alt="" className='social_media_img' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown  d-lg-none d-md-block  d-block">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            data-bs-toggle="dropdown"
                                        >
                                            Services
                                        </a>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <NavLink
                                                    className="dropdown-item nav-link"
                                                    to="/Agency"
                                                >
                                                    Agency
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    className="dropdown-item nav-link"
                                                    to="/Academy"
                                                >
                                                    Academy
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    className="dropdown-item nav-link"
                                                    to="/Advisory"
                                                >
                                                    Advisory
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    className="dropdown-item nav-link"
                                                    to="/TechnologyPartner"
                                                >
                                                    Technology Development
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown d-lg-block d-md-none d-none ktm-mega-menu">
                                        <a
                                            className={`nav-link dropdown-toggle ${scrolled ? "nav-link-scrolled" : ""}`}
                                            href="#"
                                            data-bs-toggle="dropdown"
                                        >
                                            Resources
                                        </a>

                                        <div className="dropdown-menu mega-menu p-3">
                                            <div className="mega-menu-inner">
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div className="">
                                                            <div className="tabs border-b">
                                                                {workwithusTabs.map((tab, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={
                                                                                selectedWorkWithUsTabs === tab
                                                                                    ? "tab active  "
                                                                                    : "tab "
                                                                            }
                                                                            onMouseOver={() => {
                                                                                setSelectedWorkWithUsTabs(tab);
                                                                            }}
                                                                            onClick={() => {
                                                                                if (
                                                                                    selectedWorkWithUsTabs ===
                                                                                    "Case Study"
                                                                                ) {
                                                                                    navigate("/WorkWithUs");
                                                                                } else if (
                                                                                    selectedWorkWithUsTabs ===
                                                                                    "Blogs"
                                                                                ) {
                                                                                    navigate("/ToursandWorkshops");
                                                                                } else if (
                                                                                    selectedWorkWithUsTabs ===
                                                                                    "Usefull Development"
                                                                                ) {
                                                                                    navigate("/ToursandWorkshops");
                                                                                }
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                <img src={dummyicon1} alt="" />
                                                                            </span>
                                                                            <span className='my-auto ms-2'>{tab}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        {selectedWorkWithUsTabs === "Case Study" && (
                                                            <NavLink
                                                                to="/WorkWithUs"
                                                                className="text-decoration-none "
                                                            >
                                                                <div className='row'>
                                                                    <div className='col-lg-9'>
                                                                        <div className='row'>
                                                                            <div className='col-lg-6'>
                                                                                <div className='d-flex '>
                                                                                    <div className='me-2'>
                                                                                        <img src={case_study} alt="" />
                                                                                    </div>
                                                                                    <div className='my-auto'>
                                                                                        <h6 className='text-white'>Case Study 1</h6>
                                                                                        <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-6'>
                                                                                <div className='d-flex '>
                                                                                    <div className='me-2'>
                                                                                        <img src={case_study} alt="" />
                                                                                    </div>
                                                                                    <div className='my-auto'>
                                                                                        <h6 className='text-white'>Case Study 1</h6>
                                                                                        <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-6'>
                                                                                <div className='d-flex mt-4'>
                                                                                    <div className='me-2'>
                                                                                        <img src={case_study} alt="" />
                                                                                    </div>
                                                                                    <div className='my-auto'>
                                                                                        <h6 className='text-white'>Case Study 1</h6>
                                                                                        <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-6'>
                                                                                <div className='d-flex mt-4'>
                                                                                    <div className='me-2'>
                                                                                        <img src={case_study} alt="" />
                                                                                    </div>
                                                                                    <div className='my-auto'>
                                                                                        <h6 className='text-white'>Case Study 1</h6>
                                                                                        <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-3 my-auto'>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary contact-btn d-flex"
                                                                        >
                                                                            View All
                                                                            <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                        )}
                                                        {selectedWorkWithUsTabs === "Blogs" && (
                                                            <NavLink
                                                                to="/ToursandWorkshops"
                                                                className="text-decoration-none "
                                                            >
                                                                <div className='row'>
                                                                    <div className='col-lg-4'>
                                                                        <div className=' '>
                                                                            <div className='me-2'>
                                                                                <img src={blogs} alt="" />
                                                                            </div>
                                                                            <div className='mt-3'>
                                                                                <h6 className='text-white'>Case Study 1</h6>
                                                                                <p className='text-white'>
                                                                                    Lorem Ipsum is simply dummy text of the printing.Lorem Ipsum is simply dummy text of the printing.Lorem Ipsum is simply dummy text of the printing.

                                                                                </p>
                                                                                <i class="fa-solid fa-arrow-right contact_arrow mt-2"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className=' '>
                                                                            <div className='me-2'>
                                                                                <img src={blogs} alt="" />
                                                                            </div>
                                                                            <div className='mt-3'>
                                                                                <h6 className='text-white'>Case Study 1</h6>
                                                                                <p className='text-white'>Lorem Ipsum is simply dummy text of the printing.
                                                                                    Lorem Ipsum is simply dummy text of the printing.
                                                                                    Lorem Ipsum is simply dummy text of the printing.
                                                                                    Lorem Ipsum is simply dummy text of the printing.
                                                                                </p>
                                                                                <i class="fa-solid fa-arrow-right contact_arrow mt-2"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4 my-auto '>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary contact-btn d-flex mx-auto"
                                                                        >
                                                                            View All
                                                                            <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between navbar_footer'>
                                                <div className='d-flex'>
                                                    <div className='me-5'> <i class="fa-regular fa-envelope text-white me-2"></i>
                                                        <a href="mailto:flyhigh@socialeagle.in" className='mails_tags'>flyhigh@socialeagle.in</a>
                                                    </div>
                                                    <div> <i class="fa-solid fa-phone text-white me-2"></i>
                                                        <a href="mailto:+91 8428421222" className='mails_tags'>+91 8428421222</a>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className='me-2 social_media'>
                                                        <img src={Facebook} alt="" />
                                                    </div>
                                                    <div className='me-2 social_media'>
                                                        <img src={Instagram} alt="" />
                                                    </div>
                                                    <div className='me-2 social_media'>
                                                        <img src={Twitter} alt="" />
                                                    </div>
                                                    <div>
                                                        <img src={Linkedin} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown mb-3  d-lg-none d-md-block  d-block">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            data-bs-toggle="dropdown"
                                        >
                                            Work With Us
                                        </a>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <NavLink
                                                    className="dropdown-item nav-link"
                                                    to="/WorkWithUs"
                                                >
                                                    Work With Us
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    className="dropdown-item nav-link"
                                                    to="/ToursandWorkshops"
                                                >
                                                    Tours & Workshops
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <div className='d-flex'>
                                    <button
                                        type="button"
                                        className="btn btn-primary button_red d-flex"
                                        onClick={() => navigate(`${ROUTES.CONTACTUS}`)}
                                    >
                                        Contact Us
                                        <i class="fa-solid fa-arrow-right contact_arrow my-auto ms-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div >
        </div >
    )
}

export default Navbar