import './css/style.css'
import './css/responsive.css'
import Main from './Main';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1700, 
      // easing: "ease-in-out", 
      once: false, 
    });
    AOS.refresh();
  }, []);

  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
