import React, { useState } from 'react'

const ChatBot = () => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <div
            className={`chatbox-container ${isHovered ? "hovered" : ""}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="chatbox-icon"><i class="fa-solid fa-location-arrow"></i></div>
            {isHovered && <div className="chatbox-text">Contact Us</div>}
        </div>
    );

}

export default ChatBot