import React from 'react'
import images from "../../../../helpers/images"
const ContentSection = () => {
    return (
        <div className='Agency_detail_page'>
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='purpose_left'>
                                <h1> Performance  <br className='break_tag' />Marketing</h1>
                                <button className='button_red mt-4 agency_into_button'>Consult Now</button>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='purpose_right pe-5'>
                                <p>Imagine getting results from every marketing dollar you spend. real outcomes like clicks, leads, and sales, not just vague promises. That’s the power of performance marketing: you pay only for what works. But if you don’t have a clear strategy, it’s easy to waste your budget and miss out on growth.</p>
                                <p>At Social Eagle, we don’t believe in guesswork. As a performance marketing agency in Dubai, we use data and insights to design campaigns that hit the mark every time. Our approach ensures your money works smarter, not harder, giving you the outcomes you want without the risk. Let us show you how to turn your marketing into a reliable growth engine</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='why_digital_market_left'>
                                <h2 className='headings_text_white'>What Is Performance <br className='break_tag' />Marketing?</h2>
                                <img src={images.Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-4' />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='why_digital_market_right'>
                                <p className='paraWhite_margin_top'>Performance marketing is a type of digital advertising where advertisers pay for specific actions or results rather than just for ad placements. These actions can include clicks, leads, sales, or any other measurable outcomes that indicate success. The idea is simple: you only pay when your marketing efforts achieve the desired result, making it a cost-effective and ROI-focused approach.</p>
                                <p className='paraWhite_margin_top'>Common channels used in performance marketing include pay-per-click (PPC) advertising, social media ads, and influencer marketing. Each channel tracks performance metrics to ensure that the budget is spent efficiently and that campaigns are optimized for the best results. The data-driven nature of performance marketing helps brands continuously refine their strategies to maximize the return on investment.</p>
                                <button className='button_white mt-4'> Get Consultation Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing'>
                <div className='row'>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }}>
                            <div className='container'>
                                <h2 className='headings_text_white'>Why Choose Social Eagle for Performance Marketing?</h2>
                                <p className='paraWhite_margin_top mb-5'>Our Performance Marketing services focus on delivering measurable results and maximising your return on investment.</p>
                                <img src={images.social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                                <p className='paraWhite_margin_top mt-5'>With our performance marketing expertise, we help you achieve your business goals through strategies that focus on results.
                                    Let us drive success for your brand.</p>
                                <button className='button_red mt-5'> Learn More</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_rght'>
                            <div className='agency_detail_whyChoose_rght_content mt-0'>
                                <div>
                                    <img src={images.performance_marketicon1} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Data-Driven Strategies</h5>
                                    <p>We create targeted campaigns based on data analysis to reach your specific audience effectively.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.performance_marketicon2} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Multi-Channel Approach</h5>
                                    <p> We leverage various platforms, including search engines, social media, and display networks, to boost visibility and engagement</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.performance_marketicon3} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Real-Time Optimization</h5>
                                    <p>We continuously monitor performance metrics, making adjustments to improve results and maximise your budget.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContentSection