

import Advisory_marketing_rght_img from "../assets/images/Advisory_marketing_rght_img.png";
import social_management_whyChoose from "../assets/images/social_management_whyChoose.png";

import seo_icon1 from "../assets/images/seo_icon1.png";
import seo_icon2 from "../assets/images/seo_icon2.png";
import seo_icon3 from "../assets/images/seo_icon3.png";
import seo_icon4 from "../assets/images/seo_icon4.png";

import Digital_media_icon1 from "../assets/images/Digital_media_icon1.png";
import Digital_media_icon2 from "../assets/images/Digital_media_icon2.png";
import Digital_media_icon3 from "../assets/images/Digital_media_icon3.png";

import payper_icon1 from "../assets/images/payper_icon1.png";
import payper_icon2 from "../assets/images/payper_icon2.png";
import payper_icon3 from "../assets/images/payper_icon3.png";

import performance_marketicon1 from "../assets/images/performance_marketicon1.png";
import performance_marketicon2 from "../assets/images/performance_marketicon2.png";
import performance_marketicon3 from "../assets/images/performance_marketicon3.png";

import SocialMedia_icon1 from "../assets/images/SocialMedia_icon1.png";
import SocialMedia_icon2 from "../assets/images/SocialMedia_icon2.png";
import SocialMedia_icon3 from "../assets/images/SocialMedia_icon3.png";


import Content_Marketing_icon1 from "../assets/images/Content_Marketing_icon1.png";
import Content_Marketing_icon2 from "../assets/images/Content_Marketing_icon2.png";
import Content_Marketing_icon3 from "../assets/images/Content_Marketing_icon3.png";
import Content_Marketing_icon4 from "../assets/images/Content_Marketing_icon4.png";

import Email_market_icon1 from "../assets/images/Email_market_icon1.png";
import Email_market_icon2 from "../assets/images/Email_market_icon2.png";
import Email_market_icon3 from "../assets/images/Email_market_icon3.png";
import Email_market_icon4 from "../assets/images/Email_market_icon4.png";

import creative_solution_icon1 from "../assets/images/creative_solution_icon1.png";
import creative_solution_icon2 from "../assets/images/creative_solution_icon2.png";
import creative_solution_icon3 from "../assets/images/creative_solution_icon3.png";
import creative_solution_icon4 from "../assets/images/creative_solution_icon4.png";

import video_content_icon1 from "../assets/images/video_content_icon1.png";
import video_content_icon2 from "../assets/images/video_content_icon2.png";
import video_content_icon3 from "../assets/images/video_content_icon3.png";
import video_content_icon4 from "../assets/images/video_content_icon4.png";
import video_content_icon5 from "../assets/images/video_content_icon5.png";

import market_Automation_icon1 from "../assets/images/market_Automation_icon1.png";
import market_Automation_icon2 from "../assets/images/market_Automation_icon2.png";
import market_Automation_icon3 from "../assets/images/market_Automation_icon3.png";
import market_Automation_icon4 from "../assets/images/market_Automation_icon4.png";
import market_Automation_icon5 from "../assets/images/market_Automation_icon5.png";


import Funnel_marketing_icon1 from "../assets/images/Funnel_marketing_icon1.png";
import Funnel_marketing_icon2 from "../assets/images/Funnel_marketing_icon2.png";
import Funnel_marketing_icon3 from "../assets/images/Funnel_marketing_icon3.png";
import Funnel_marketing_icon4 from "../assets/images/Funnel_marketing_icon4.png";
import Funnel_marketing_icon5 from "../assets/images/Funnel_marketing_icon5.png";
import Funnel_marketing_icon6 from "../assets/images/Funnel_marketing_icon6.png";
import Funnel_marketing_icon7 from "../assets/images/Funnel_marketing_icon7.png";
import Funnel_marketing_icon8 from "../assets/images/Funnel_marketing_icon8.png";
import Funnel_marketing_icon9 from "../assets/images/Funnel_marketing_icon9.png";

import video_marketing_icon1 from "../assets/images/video_marketing_icon1.png";
import video_marketing_icon2 from "../assets/images/video_marketing_icon2.png";
import video_marketing_icon3 from "../assets/images/video_marketing_icon3.png";
import video_marketing_icon4 from "../assets/images/video_marketing_icon4.png";
import video_marketing_icon5 from "../assets/images/video_marketing_icon5.png";
import video_marketing_icon6 from "../assets/images/video_marketing_icon6.png";
import video_marketing_icon7 from "../assets/images/video_marketing_icon7.png";
import video_marketing_icon8 from "../assets/images/video_marketing_icon8.png";

import SocialMarket_icon1 from "../assets/images/SocialMarket_icon1.png";
import SocialMarket_icon2 from "../assets/images/SocialMarket_icon2.png";
import SocialMarket_icon3 from "../assets/images/SocialMarket_icon3.png";

import contact_bar from "../assets/images/contact_bar.png";
import contact_bar_bottom from "../assets/images/contact_bar_bottom.png";

import contact_banner_img1 from "../assets/images/contact_banner_img1.png";
import contact_banner_img2 from "../assets/images/contact_banner_img2.png";


import Agency_tab_image from "../assets/images/Agency_tab_image.png";
import Agency_tab_image_right from "../assets/images/Agency_tab_image_right.png";
import agency_listing_background from "../assets/images/agency_listing_background.png";
const images = {
    // images
    Agency_tab_image,
    Agency_tab_image_right,
    agency_listing_background,
    



    // 
    contact_bar,contact_bar_bottom,contact_banner_img1,contact_banner_img2,
    performance_marketicon1, performance_marketicon2, performance_marketicon3,
    SocialMedia_icon1, SocialMedia_icon2, SocialMedia_icon3,
    SocialMarket_icon1, SocialMarket_icon2, SocialMarket_icon3,
    Advisory_marketing_rght_img,
    social_management_whyChoose,
    seo_icon1,
    seo_icon2,
    seo_icon3,
    seo_icon4,
    Content_Marketing_icon1,
    Content_Marketing_icon2,
    Content_Marketing_icon3,
    Content_Marketing_icon4,
    Email_market_icon1,
    Email_market_icon2,
    Email_market_icon3,
    Email_market_icon4,
    creative_solution_icon1,
    creative_solution_icon2,
    creative_solution_icon3,
    creative_solution_icon4,
    video_content_icon1,
    video_content_icon2,
    video_content_icon3,
    video_content_icon4,
    video_content_icon5,
    Funnel_marketing_icon1,
    Funnel_marketing_icon2,
    Funnel_marketing_icon3,
    Funnel_marketing_icon4,
    Funnel_marketing_icon5,
    Funnel_marketing_icon6,
    Funnel_marketing_icon7,
    Funnel_marketing_icon8,
    Funnel_marketing_icon9,
    video_marketing_icon1,
    video_marketing_icon2,
    video_marketing_icon3,
    video_marketing_icon4,
    video_marketing_icon5,
    video_marketing_icon6,
    video_marketing_icon7,
    video_marketing_icon8,
    Digital_media_icon1,
    Digital_media_icon2,
    Digital_media_icon3,
    market_Automation_icon1,
    market_Automation_icon2,
    market_Automation_icon3,
    market_Automation_icon4,
    market_Automation_icon5,
    payper_icon1,
    payper_icon2, payper_icon3,

};

export default images;