export const branding_designing_content = [
    {
        heading: "Creative Solutions",
        content: ["Discover how our Creative Solutions can help your brand stand out.",
            "In a crowded market, you need fresh ideas that connect with your audience.",
            "We take the time to understand your unique challenges and create custom strategies that grab attention and drive engagement.",
            "Whether it's an eye-catching ad, beautiful visuals, or engaging content, we combine creativity with strategy to achieve real results."
            , "Let us turn your vision into reality and elevate your brand.", "Your success is our goal, and we’re here to make it happen!"],
        RedirectLink: "/agency/creative-solutions"
    },
    {
        heading: "Video Content Production",
        content: ["Our Video Content Production services bring your ideas to life through engaging visuals and storytelling.",
            "In today's digital world, video is one of the most effective ways to connect with your audience.",
            "We handle everything from concept development to filming and editing, ensuring your message is clear and impactful.",
            "Whether you need promotional videos, tutorials, or brand stories, we create high-quality content that captures attention and drives engagement."
            , "Let us help you tell your story in a way that resonates and leaves a lasting impression!"],
        RedirectLink: "/agency/video-content-production"
    },
    {
        heading: "Video Marketing",
        content: ["Boost your brand with video marketing!",
            "Video is a powerful way to engage your audience and share your message.",
            "We create tailored video campaigns to help you promote products, showcase customer testimonials, or build brand awareness. ",
            "Our team makes sure every video is optimized for different platforms to reach the widest audience. "
            , "Let us help you connect with your audience through engaging videos that get results and increase your brand visibility!"],
        RedirectLink: "/agency/video-marketing"
    },
    {
        heading: "Funnel Building & Marketing",
        content: ["Transform your leads into loyal customers with our Funnel Building & Marketing services.",
            "We design and create effective sales funnels that guide potential customers through each stage of their journey, from awareness to purchase.",
            "By understanding your audience’s needs, we craft tailored content and experiences that nurture leads and encourage conversions.",
            "Our approach combines proven strategies and data-driven insights to optimize every step of the funnel."
            , "Let us help you streamline your marketing efforts and turn interested visitors into satisfied customers!"],
        RedirectLink: "/agency/funnel-building-marketing"
    },

]