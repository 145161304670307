const ROUTES = {
    HOME: '/home',
    ABOUTUS: '/',
    CONTACTUS: '/contact-us',
    TECHNOLOGYDEVELOPMENT: '/technology-development',
    ADVISORY: '/advisory',
    ADVISORY_SERVICES: {
        BRANDING_STRATEGY_CONSULTING: '/advisory/branding-strategy-and-consulting',
        MARKETING_STRATEGY_CONSULTING: '/advisory/marketing-strategy-and-consulting',
        MARKETING_PROCESS_AUTOMATION: '/advisory/marketing-process-and-automation',
        COMMUNITY_MASTERMIND_CREATION: '/advisory/community-and-mastermind-creation'
    },
    AGENCY: '/agency',
    AGENCY_SERVICES: {
        COMMUNITY_AND_MASTERMIND_CREATION: '/agency/community-and-mastermind-creation',
        SOCIAL_MEDIA_MANAGEMENT: '/agency/social-media-management',
        SOCIAL_MEDIA_MARKETING: '/agency/social-media-marketing',
        PAY_PER_CLICK: '/agency/pay-per-click',
        PERFORMANCE_MARKETING: '/agency/performance-marketing',
        DIGITAL_MEDIA_PLANNING_AND_BUYING: '/agency/digital-media-planning-and-buying',
        SEARCH_ENGINE_OPTIMIZATION: '/agency/search-engine-optimization',
        CONTENT_MARKETING_SERVICE: '/agency/content-marketing-service',
        EMAIL_MARKETING_SERVICE: '/agency/email-marketing-service',
        MARKETING_AUTOMATION: '/agency/marketing-automation',
        VIDEO_CONTENT_PRODUCTION: '/agency/video-content-production',
        CREATIVE_SOLUTIONS: '/agency/creative-solutions',
        VIDEO_MARKETING: '/agency/video-marketing',
        FUNNEL_BUILDING_MARKETING: '/agency/funnel-building-marketing'
    },
    BLOG: '/blogs',

};

export default ROUTES;





