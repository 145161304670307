import React, { useEffect, useRef, useState } from 'react'

import { techno_main_content } from "../../helpers/contents/TechnologyMainContent";
import { Key_Offerings } from "../../helpers/contents/TechnlogyKeyOfferings";


const TechnologyPartnerContent = () => {
  const [technologyTabs, setTechnologyTabs] = useState(0)
  const technology_tabs = ["Web & Mobile App", "E-com Custom Website", "Shopify", "WordPress",
    "Web & Mobile App Maintenance", "Machine Learning & AI", "UI/UX"]
  const [isSticky, setIsSticky] = useState(false);
  const [originalOffset, setOriginalOffset] = useState(0);
  const lastSectionRef = useRef(null);
  const [showTabs, setShowTabs] = useState(true);
  useEffect(() => {
    const tabs = document.querySelector(".technology_tabs");
    const navbar = document.querySelector(".navbar");
    setOriginalOffset(tabs.offsetTop);
    const handleScroll = () => {
      const navbarHeight = navbar?.offsetHeight || 0;
      const currentScrollY = window.scrollY;

      if (currentScrollY > originalOffset - navbarHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      if (lastSectionRef.current) {
        const lastSectionBottom =
          lastSectionRef.current.getBoundingClientRect().bottom +
          window.scrollY;
        const windowBottom = currentScrollY + window.innerHeight;

        if (windowBottom >= lastSectionBottom) {
          setShowTabs(false); // Hide tabs when at the end
        } else {
          setShowTabs(true); // Show tabs otherwise
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [originalOffset]);
  const videoRefs = useRef([]);
  const handleTabClick = (index) => {
    setTechnologyTabs(index);
    if (videoRefs.current[index]) {
      const offsetTop =
        videoRefs.current[index].getBoundingClientRect().top +
        window.pageYOffset -
        100; // Adjust for sticky header height
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    const tabs = document.querySelector(".technology_tabs");
    const navbar = document.querySelector(".navbar");

    setOriginalOffset(tabs.offsetTop);

    const handleScroll = () => {
      const navbarHeight = navbar?.offsetHeight || 0;
      const currentScrollY = window.scrollY;
      if (currentScrollY > originalOffset - navbarHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      videoRefs.current.forEach((section, index) => {
        if (section) {
          const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 200; // Adjust for header
          const sectionBottom = sectionTop + section.offsetHeight;
          if (currentScrollY >= sectionTop && currentScrollY < sectionBottom) {
            setTechnologyTabs(index);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [originalOffset]);


  return (
    <div>
      <section className=''>
        <div className='purposeof_se_parent_section mb-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='purpose_left'>
                  <h1>Technology <br className='break_tag' />Development</h1>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='purpose_right mt-4'>
                  <p>Empower your business with our full suite of services, from Web & Mobile App Development to custom eCommerce and AI solutions.
                    Get tailored Shopify, WordPress, and UI/UX design expertise, plus reliable ongoing maintenance.</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 '>
                <div>
                  {showTabs && (
                    <div className={`technology_tabs ${isSticky ? "sticky" : ""} d-flex justify-content-between px-2 mx-auto`} style={isSticky ? { top: "60px" } : {}}>
                      {/* {technology_tabs.map((item, index) => (
                        <p className={technologyTabs === index ? "techno_tab_active " : "techno_tab_link"}
                          key={index}
                          onClick={() => {
                            setTechnologyTabs(index);
                            handleTabClick(index);
                          }} ref={(el) => (videoRefs.current[index] = el)}>{item}</p>
                      ))} */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {techno_main_content.map((item, index) => (
          <>
            <div ref={(el) => (videoRefs.current[index] = el)}>
              <div className={`technology_mainContent_div ${isSticky ? "sticky" : ""} ${isSticky && index === 0 ? "first_technology_tab" : ""} `} >
                <div className='row'>
                  <div className={`col-lg-5 ${index % 2 === 0 ? 'order-1' : "order-2"}`}>
                    <div className='technology_parent_left'>
                      <img src={item?.image_url} alt=".." className='techno_image' />
                    </div>
                  </div>
                  <div className={`col-lg-7 ${index % 2 === 0 ? 'order-2' : "order-1"} d-flex justify-content-center align-items-center`}>
                    <div className='technology_parent_right'>
                      <div className='d-flex'>
                        <p className='heading_serialnumber'>{index + 1}</p>
                        <h3 className='heading_para my-auto ms-3'>{item?.heading}</h3>
                      </div>
                      <p className='mt-4 tech_parent_para'>{item?.content1}</p>
                      <p className='mt-3 tech_parent_para'>{item?.content2}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='technology_keyOfferings'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-5 my-auto'>
                      <div className='key_offerings_right '>
                        <h1>KEY<br className='break_tag' /> OFFERINGS</h1>
                        <button className='button_red mt-5'>Get in touch</button>
                      </div>
                    </div>
                    <div className='col-lg-7'>
                      <div className='row justify-content-between'>
                        {Key_Offerings[index]?.heading?.map((item, i) => (
                          <div className='col-lg-6'>
                            <div className='d-flex flex-column mt-5 me-5 keyofferings_content'>
                              <p className='key_offer_number'>0{i + 1}</p>
                              <p className='key_offer_right_head'>{item}</p>
                              <p className='key_offer_para text-white mt-2'>{Key_Offerings[index]?.content[i]}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}

      </section>
    </div>
  )
}

export default TechnologyPartnerContent