import React, { useEffect, useRef, useState } from 'react'

const BlogsContent = () => {
    const technology_tabs = ["dummy 1 blogs", "dummy 2 blogs", "dummy 3 blogs", "dummy 4"]
    const [technologyTabs, setTechnologyTabs] = useState(0)
    const [isSticky, setIsSticky] = useState(false);
    const [originalOffset, setOriginalOffset] = useState(0);
    const lastSectionRef = useRef(null);
    const [showTabs, setShowTabs] = useState(true);
    const handleTabClick = (index) => {
        setTechnologyTabs(index);
        if (videoRefs.current[index]) {
            const offsetTop =
                videoRefs.current[index].getBoundingClientRect().top +
                window.pageYOffset -
                100; // Adjust for sticky header height
            window.scrollTo({
                top: offsetTop,
                behavior: "smooth",
            });
        }
    };
    const videoRefs = useRef([]);
    useEffect(() => {
        const tabs = document.querySelector(".technology_tabs");
        const navbar = document.querySelector(".navbar");

        setOriginalOffset(tabs.offsetTop);
        const handleScroll = () => {
            const navbarHeight = navbar?.offsetHeight || 0;
            const currentScrollY = window.scrollY;
            if (currentScrollY > originalOffset - navbarHeight) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
            videoRefs.current.forEach((section, index) => {
                if (section) {
                    const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 200; // Adjust for header
                    const sectionBottom = sectionTop + section.offsetHeight;
                    if (currentScrollY >= sectionTop && currentScrollY < sectionBottom) {
                        setTechnologyTabs(index);
                    }
                }
            });
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [originalOffset]);

    return (
        <div className='container'>
            <div className='blogs_content_parent section_padding'>
                <div>
                    {showTabs && (
                        <div className={`technology_tabs ${isSticky ? "sticky" : ""} d-flex justify-content-between px-2 mx-auto`} style={isSticky ? { top: "60px" } : {}}>
                            {technology_tabs.map((item, index) => (
                                <p className={technologyTabs === index ? "techno_tab_active " : "techno_tab_link"}
                                    key={index}
                                    onClick={() => {
                                        setTechnologyTabs(index);
                                        handleTabClick(index);
                                    }} ref={(el) => (videoRefs.current[index] = el)}>{item}</p>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default BlogsContent
