import React, { useEffect } from 'react'
import TechnologyPartnerBanner from '../component/TechnologyPartner.js/TechnologyPartnerBanner'
import TechnologyPartnerContent from '../component/TechnologyPartner.js/TechnologyPartnerContent'
import Footer from '../component/Footer/Footer'
import Navbar from '../component/Navbar/Navbar'

const TechnologyPartner = () => {
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
    return (
        <div className='aboutus_main overflow-hidden'>
            <Navbar />
            <TechnologyPartnerBanner />
            <TechnologyPartnerContent />
            <Footer />
        </div>
    )
}

export default TechnologyPartner