import React from 'react'
import images from "../../../../helpers/images"

const ContentSection = () => {
    return (
        <div className='Agency_detail_page'>
            <div className='intro section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='purpose_left'>
                                <h1> Search Engine <br className='break_tag' />Optimization (SEO) </h1>
                                <button className='button_red mt-4 agency_into_button'>Learn More</button>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='purpose_right pe-5'>
                                <p>Looking to improve your website's visibility and attract more organic traffic? That's where our SEO services come in. We specialize in enhancing your online presence and driving measurable results.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='why_digital_market_section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='why_digital_market_left'>
                                <h2 className='headings_text_white'>Why Do SEO Need For<br className='break_tag' /> Your Website?</h2>
                                <img src={images.Advisory_marketing_rght_img} alt="../" className='why_digital_market_image mt-4' />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='why_digital_market_right'>
                                <p className='paraWhite_margin_top'>In today’s digital landscape, Search Engine Optimization (SEO) is not just a buzzword; it’s a cornerstone of online success. With the right SEO strategy, your website doesn’t just climb the search engine ranks—it gains visibility, authority, and reaches an audience actively seeking what you offer. SEO is the bridge that connects your website to potential customers, driving organic traffic and converting visitors into loyal clients.</p>
                                <p className='paraWhite_margin_top'>But SEO goes beyond the surface of keywords and rankings. It’s a holistic approach to website enhancement, where every aspect—from content to design, structure to speed—works in harmony to create a seamless user experience. When search engines like Google rank your website, they look for more than just relevant keywords. They evaluate how well your site meets the needs of users: how fast it loads, how secure it is, how mobile-friendly it is, and how effectively it answers the user’s query.</p>
                                <button className='button_white mt-4'> Get Consultation Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='Agency_added_section'>
                <div className='container'>
                    <div className='Agency_added_section_heading section_padding'>
                        <h1 className='heading_para text-center'>A Well-Optimized Site Has<br className='break_tag' /> Multiple Advantages, Including  </h1>
                    </div>
                    <div className='section_padding_bottom'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex'>
                                    <div><p className='number_list_backred'>1</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Increased Visibility</h4>
                                        <p>By ranking higher in search engine results pages (SERPs), your site becomes easier to find, attracting qualified visitors who are more likely to engage with your content.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex me-0'>
                                    <div><p className='number_list_backred'>2</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Better User Experience</h4>
                                        <p> SEO focuses on improving the structure, speed, and usability of your website, leading to a smoother and more enjoyable experience for users—and ultimately, a lower bounce rate.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex'>
                                    <div><p className='number_list_backred'>3</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Credibility and Trust</h4>
                                        <p> Websites that appear at the top of search results are often perceived as more trustworthy and credible. SEO enhances your brand’s authority, fostering trust with your audience.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content d-flex me-0'>
                                    <div><p className='number_list_backred'>4</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Higher Conversion Rates</h4>
                                        <p>With targeted SEO, you draw in users who are actively searching for products or services like yours, leading to higher engagement and conversions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Agency_added_content Agency_added_content_lastsection d-flex'>
                                    <div><p className='number_list_backred'>5</p></div>
                                    <div className='Agency_added_flexdiv'>
                                        <h4 className='heading_para my-auto'>Cost Efficiency</h4>
                                        <p>Unlike paid advertising, SEO provides long-term, sustainable results. Once you’re established in search rankings, maintaining your position becomes more cost-effective, generating organic traffic without continuous ad spend.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='agency_detail_listing'>
                <div className='row'>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_left' style={{ backgroundColor: "black" }}>
                            <div className='container'>
                                <h2 className='headings_text_white'>Why Choose Our SEO <br className='break_tag' />Services?</h2>
                                <p className='paraWhite_margin_top mb-5'>The answer is simple: we focus on results. While anyone can add keywords, our team dives deep to create a strategic, data-backed approach to SEO that delivers lasting growth.</p>
                                <img src={images.social_management_whyChoose} alt="../" className='details_page_whyChoose' />
                                <p className='paraWhite_margin_top mt-5'>With our expertise in SEO, we help your business achieve higher rankings, drive consistent traffic, and increase conversions. Ready to enhance your online presence?.</p>
                                <button className='button_red mt-5'> Get Your FREE SEO Analysis</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 px-0'>
                        <div className='agency_detail_whyChoose_rght'>
                            <div className='agency_detail_whyChoose_rght_content mt-0'>
                                <div>
                                    <img src={images.seo_icon1} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Strategic Ad Creation</h5>
                                    <p>We conduct an in-depth audit of your website, uncovering areas for improvement and opportunities to boost rankings.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.seo_icon2} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Targeted Campaigns</h5>
                                    <p> We fine-tune your website’s content, structure, and meta tags, ensuring it performs well across search engines and attracts the right audience.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.seo_icon3} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Performance Analysis</h5>
                                    <p>Using advanced tools, we identify relevant, high-traffic keywords that potential customers are actively searching for, aligning your content to match user intent.</p>
                                </div>
                            </div>
                            <div className='agency_detail_whyChoose_rght_content'>
                                <div>
                                    <img src={images.seo_icon4} alt="../" className='our_value_icon' />
                                </div>
                                <div>
                                    <h5 className='headings_text_white'>Performance Analysis</h5>
                                    <p>Our team develops a tailored content plan that not only attracts visitors but also engages and converts them, keeping your audience coming back.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContentSection