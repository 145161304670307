import React, { useEffect, useRef, useState } from 'react'
import AdvisoryCommonSections from './AdvisoryCommonSections'
import Images from "../../helpers/images"

const AdvisoryContent = () => {
    const advisory_content = [
        {
            heading: "Marketing Strategy & Consulting",
            content: [
                "Unlock your brand's fullest potential with our Marketing Strategy & Consulting services.",
                "We collaborate with you to understand your business goals, set realistic goals, find target audience, and market dynamics.",
                "Our experts then develop customized strategies that align with your objectives, whether it's increasing brand awareness, driving sales, or entering new markets.",
                "We analyze data, identify opportunities, and provide actionable insights to ensure your marketing efforts are effective and results-driven.",
                "Let us guide you in navigating the ever-changing marketing landscape and help your business thrive!"
            ]
        },
        {
            heading: "Marketing Process & Automation",
            content: [
                "Streamline your marketing efforts with our Marketing Process & Automation Consulting services.",
                "We help you design and implement efficient marketing processes that save time and resources.",
                "Our team assesses your current workflows, identifies bottlenecks, and introduces automation tools tailored to your needs.",
                "By automating repetitive tasks, you can focus on what truly matters for growing your business.",
                "We guide you in setting up systems for lead generation, email marketing, social media management, and more, ensuring everything runs smoothly.",
                "Let us help you maximize efficiency and drive better results with our expert consulting!"
            ]
        },
        {
            heading: "Branding Strategy & Consulting",
            content: ["Elevate your brand with our Branding Strategy & Consulting services. ",
                "We work closely with you to understand your vision, mission, and values, helping to shape a unique identity that resonates with your target audience. ",
                "Our team conducts thorough market research and competitive analysis to identify opportunities for differentiation. ",
                "We develop a comprehensive branding strategy that includes messaging, visual identity, and positioning, ensuring consistency across all touchpoints. ",
                "Let us guide you in building a strong brand that stands out in the market and fosters lasting connections with your customers!"
            ]
        },
        {
            heading: "Community & Mastermind Creation",
            content: ["Build strong connections and grow together with our Community & Mastermind Creation services. ",
                "If you're a coach or someone selling services, community building is essential for boosting sales. ",
                "Creating a strong community fosters relationships, encourages engagement, and builds trust among your audience. ",
                "When people feel connected and supported, they’re more likely to choose your services and recommend you to others. ",
                "A vibrant community not only provides valuable feedback but also helps you understand your clients' needs better. ",
                "By nurturing these connections, you can turn followers into loyal customers, driving growth and increasing your sales effectively.",
                "We help you create lively communities where like-minded people can share ideas and support each other. ",
                "Our team assists in forming mastermind groups that encourage collaboration and accountability. ",
                "By creating a supportive environment, we help members tackle challenges and reach their goals more easily. ",
                "Let us help you create a thriving community that inspires and empowers everyone to succeed!"
            ]
        },
    ]
    const [advisoryTabs, setAdvisoryTabs] = useState(0)
    const Advisory_tabs = ["Marketing Strategy & Consulting", "Marketing Process & Automation",
        "Branding Strategy & Consulting", "Community & Mastermind Creation"]
    const [isSticky, setIsSticky] = useState(false);
    const [originalOffset, setOriginalOffset] = useState(0);

    useEffect(() => {
        const tabs = document.querySelector(".technology_tabs");
        const navbar = document.querySelector(".navbar");
        setOriginalOffset(tabs.offsetTop);
        const handleScroll = () => {
            const navbarHeight = navbar?.offsetHeight || 0;
            const currentScrollY = window.scrollY;

            if (currentScrollY > originalOffset - navbarHeight) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [originalOffset]);

    const videoRefs = useRef([]);
    const handleTabClick = (index) => {
        setAdvisoryTabs(index);
        if (videoRefs.current[index]) {
            const offsetTop =
                videoRefs.current[index].getBoundingClientRect().top +
                window.pageYOffset -
                100; // Adjust for sticky header height
            window.scrollTo({
                top: offsetTop,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        const tabs = document.querySelector(".technology_tabs");
        const navbar = document.querySelector(".navbar");

        setOriginalOffset(tabs.offsetTop);

        const handleScroll = () => {
            const navbarHeight = navbar?.offsetHeight || 0;
            const currentScrollY = window.scrollY;
            if (currentScrollY > originalOffset - navbarHeight) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
            videoRefs.current.forEach((section, index) => {
                if (section) {
                    const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 200; // Adjust for header
                    const sectionBottom = sectionTop + section.offsetHeight;
                    if (currentScrollY >= sectionTop && currentScrollY < sectionBottom) {
                        setAdvisoryTabs(index);
                    }
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [originalOffset]);

    return (
        <>
            <div className='advisory_parent_section'>
                <section className='Advisory_tab_parent'>
                    <div className='container'>
                        <div className='purpose_left section_padding_top'>
                            <h1 className='text-center text-white mb-5'>Advisory </h1>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className={`technology_tabs ${isSticky ? "sticky" : ""} d-flex justify-content-between px-2 `} style={isSticky ? { top: "50px" } : {}}>
                                    {Advisory_tabs.map((item, index) => (
                                        <p className={advisoryTabs === index ? "techno_tab_active " : "techno_tab_link"}
                                            key={index} onClick={() => {
                                                setAdvisoryTabs(index);
                                                handleTabClick(index);
                                            }} ref={(el) => (videoRefs.current[index] = el)}>{item}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {advisory_content?.map((item, index) => (
                    <section className={`advisory_content_parent position-relative ${isSticky && index === 0 ? "first_advisory_tab" : ""}`} key={index} ref={(el) => (videoRefs.current[index] = el)}>
                        <div className="container">
                            <div className="row">
                                <div className={`col-lg-6 ${index % 2 == 0 ? "order-1" : "order-2"}`}>
                                    <div className="advisory_content_left mt-5" >
                                        <h2 className={`${index % 2 == 0 ? "text-start" : "text-end"}`}>{item?.heading}</h2>
                                        {index % 2 == 0 ?
                                            <img src={Images.Agency_tab_image} alt='...'
                                                className="Advisory_bottom_image_left" /> :
                                            <img src={Images.Agency_tab_image_right} alt='...'
                                                className="Advisory_bottom_image_right" />}
                                    </div>
                                </div>
                                <div className={`col-lg-6 ${index % 2 == 0 ? "order-2" : "order-1"}`}>
                                    <div className="advisory_content_right section_padding">
                                        {item?.content?.map((paragraph, contentIndex) => (
                                            <p className="text-white" key={contentIndex}>
                                                {paragraph}
                                            </p>
                                        ))}
                                        <button className="button_white mt-5">Get in touch</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                ))}
            </div>
            <AdvisoryCommonSections />
        </>
    )
}

export default AdvisoryContent