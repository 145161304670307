import React from 'react'
import Advisory_marketing_rght_img from "../../../../assets/images/Advisory_marketing_rght_img.png";
const ContentSection = () => {
    return (
        <div>
            <div className='advisory_parent_section'>
                <div className='agency_detail_heading'>
                    <div className='purpose_left section_padding'>
                        <h1 className='text-center text-white'>Marketing Process<br className='break_tag' /> & Automation</h1>
                    </div>
                    <div className='container'>
                        <div className=''>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className='advisory_details_content_left'>
                                        <h2 className='heading_para text-white'>Understanding Your <br className='break_tag' />Business Goals</h2>
                                        <p>Unlock your brand's fullest potential with our Marketing Strategy & Consulting services.
                                            Our experts collaborate closely with you to understand your business goals,
                                            whether that means increasing brand awareness, driving sales, or entering new markets</p>
                                        <p>By setting realistic objectives, we ensure your marketing efforts align with your overall business strategy.</p>
                                    </div>
                                </div>
                                <div className='col-lg-5 mx-auto'>
                                    <div className='advisory_details_content_right '>
                                        <img src={Advisory_marketing_rght_img} alt="../" className='w-100 Advisory_marketing_rght_img' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='section_padding'>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className='advisory_details_content_right '>
                                        <img src={Advisory_marketing_rght_img} alt="../" className='w-100 Advisory_marketing_left_img' />
                                    </div>
                                </div>
                                <div className='col-lg-5 mx-auto'>
                                    <div className='advisory_details_content_left'>
                                        <h2 className='heading_para text-white'>Understanding Your <br className='break_tag' />Business Goals</h2>
                                        <p>Unlock your brand's fullest potential with our Marketing Strategy & Consulting services.
                                            Our experts collaborate closely with you to understand your business goals,
                                            whether that means increasing brand awareness, driving sales, or entering new markets</p>
                                        <p>By setting realistic objectives, we ensure your marketing efforts align with your overall business strategy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection