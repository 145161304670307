import React from 'react'
import contact_banner_img1 from "../../assets/images/contact_banner_img1.png";
import contact_banner_img2 from "../../assets/images/contact_banner_img2.png";
import contact_bar from "../../assets/images/contact_bar.png";
import contact_bar_bottom from "../../assets/images/contact_bar_bottom.png";
import { NavLink } from 'react-router-dom';

const AgencyBanner = () => {
  return (
    <div>
      <section className='aboutus_banner_section position-relative'>
        <div className='aboutus_parent_section '>
          <img src={contact_bar_bottom} alt="" className='bar_about_bottom' />
          <img src={contact_bar} alt="" className='bar_about_top' />
          <div className='container'>
            <div className='row'>
              <div className='contact_banner_parent position-relative'>             
                <p className='mb-3 text-white'>Agency</p>
                <h1 className='contact_banner_head text-white'>Agency <img src={contact_banner_img1} alt="" className='contact_banner_img' /> Agency</h1>
                <h1 className='contact_banner_head text-white'><img src={contact_banner_img2} alt="" className='contact_banner_img2' /> ipsum</h1>
                <h1 className='contact_banner_head text-white'>Lorum <img src={contact_banner_img1} alt="" className='contact_banner_img' /> ipsum</h1>
                <NavLink to={"/?id=home-below-section"} class="circle-div">
                  <div className='banner_circle_loop'>
                    <div class="circle-center-link">
                      <div class="circle-center">
                        <i class="fa-solid fa-computer-mouse"></i>
                      </div>
                      <div id="circle" class="rotate">
                        <svg width="300px" height="300px" viewBox="0 0 300 300">
                          <defs>
                            <path
                              id="circlePath"
                              d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                            ></path>
                          </defs>
                          <circle cx="150" cy="150" r="60" fill="none"></circle>
                          <g>
                            <use xlinkHref="#circlePath" fill="none"></use>
                            <text
                              fill="#fff"
                              font-size="10.5"
                              fontWeight="normal   "
                            >
                              <textPath xlinkHref="#circlePath">
                                &nbsp; &#183; &nbsp; FLY &nbsp; HIGH &nbsp; WITH
                                &nbsp; SOCIAL &nbsp; EAGLE &nbsp; &#183; &nbsp;
                                FLY &nbsp; HIGH &nbsp; WITH &nbsp; SOCIAL &nbsp;
                                EAGLE
                              </textPath>
                            </text>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

      </section >
    </div>
  )
}

export default AgencyBanner