import React, { useState } from 'react';
import india_flag from "../../assets/images/india_flag.png";
import dubai_flag from "../../assets/images/dubai_flag.png";

const ContactUsContent = () => {
    const [map, setMap] = useState("chennai");

    return (
        <>
            <div className="get_in_touch">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form_parent_div">
                                <h4 className="form_heading">Your Vision, Our Mission:</h4>
                                <h4 className="form_heading">Let's Shape Success Together.</h4>
                                <form className="mt-4">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <input type="text" placeholder="Name" className="form_input w-100" maxLength={50} />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="tel" placeholder="Mobile Number" className="form_input w-100" />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="email" placeholder="Email" className="form_input w-100" />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" placeholder="Company Name" className="form_input w-100" maxLength={50} />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" placeholder="Avg. Monthly Marketing Budget" className="form_input w-100" />
                                        </div>
                                        <div className="col-lg-12">
                                            <input type="tel" placeholder="Website URL" className="form_input w-100" />
                                        </div>
                                        <div className="col-lg-12">
                                            <textarea placeholder="Message" className="form_input w-100 pt-2" style={{ height: "100px" }} />
                                        </div>
                                    </div>
                                    <button type="submit" className="submit_btn mt-4">Submit <i className="fa-solid fa-arrow-right ms-2"></i></button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="get_in_touch_right ps-3">
                                <h4 className="form_heading">Get In Touch</h4>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="address_div mt-4" style={{ cursor: "pointer" }} onClick={() => setMap("chennai")}>
                                            <div>
                                                <img src={india_flag} alt="India Flag" className="mb-2" />
                                            </div>
                                            <div className="mt-3">
                                                <p className="mb-2">India</p>
                                                <p>No. 14-16, Global Hospital Road Junction, Indira Priyadarshini Nagar, Opp. ICICI Bank,
                                                    Perumbakkam,
                                                    Chennai,
                                                    Tamil Nadu 600100</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="address_div mt-4" style={{ cursor: "pointer" }} onClick={() => setMap("dubai")}>
                                            <div>
                                                <img src={dubai_flag} alt="Dubai Flag" className="mb-2" />
                                            </div>
                                            <div className="mt-3">
                                                <p className="mb-2">Dubai</p>
                                                <p>No. 14-16, Global Hospital Road Junction, Indira Priyadarshini Nagar, Opp. ICICI Bank,
                                                    Perumbakkam,
                                                    Chennai,
                                                    Tamil Nadu 600100</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {map === "chennai" ? (
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3889.039012492159!2d80.208154!3d12.905213!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d14f00995f7%3A0xb37cd82fd02a1480!2sSocial%20Eagle%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1730913569771!5m2!1sen!2sin"
                                            width="100%"
                                            height="280"
                                            style={{ border: "0", marginTop: "30px" }}
                                            allowFullScreen
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        ></iframe>
                                    ) : (
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d925134.3210715873!2d54.568041327437584!3d25.0745656650172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1730914075969!5m2!1sen!2sin"
                                            width="100%"
                                            height="280"
                                            style={{ border: "0", marginTop: "30px" }}
                                            allowFullScreen
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        ></iframe>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default ContactUsContent;
