import React from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import Agency from './pages/Agency';
import Blogs from './pages/Blogs';
import TechnologyPartner from './pages/TechnologyPartner';
import Advisory from './pages/Advisory';
import MarketingStrategyAndConsulting from './pages/MarketingStrategyAndConsulting';
import MarketingProcessAndAutomation from './pages/MarketingProcessAndAutomation';
import BrandingStrategyAndConsulting from './pages/BrandingStrategyAndConsulting';
import CommunityAndMastermindCreation from './pages/CommunityAndMastermindCreation';
import SocialMediaManagement from './pages/SocialMediaManagement';
import SocialMediaMarketing from './pages/SocialMediaMarketing';
import PayPerClick from './pages/PayPerClick';
import PerformanceMarketing from './pages/PerformanceMarketing';
import DigitalMediaPlanningAndBuying from './pages/DigitalMediaPlanningAndBuying';
import SearchEngineOptimization from './pages/SearchEngineOptimization';
import ContentMarketingService from './pages/ContentMarketingService';
import EmailMarketingService from './pages/EmailMarketingService';
import MarketingAutomation from './pages/MarketingAutomation';
import CreativeSolutions from './pages/CreativeSolutions';
import VideoContentProduction from './pages/VideoContentProduction';
import VideoMarketing from './pages/VideoMarketing';
import FunnelBuildingMarketing from './pages/FunnelBuildingMarketing';
import ROUTES from './helpers/constants/routes';

const Main = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route>
                    {/* Common Routes */}
                    <Route path={ROUTES.CONTACTUS} element={<ContactUs />} />
                    <Route path={ROUTES.ABOUTUS} element={<AboutUs />} />
                    <Route path={ROUTES.TECHNOLOGYDEVELOPMENT} element={<TechnologyPartner />} />

                    {/* Advisory Routes */}
                    <Route path={ROUTES.ADVISORY} element={<Advisory />} />
                    <Route path={ROUTES.ADVISORY_SERVICES.MARKETING_STRATEGY_CONSULTING} element={<MarketingStrategyAndConsulting />} />
                    <Route path={ROUTES.ADVISORY_SERVICES.MARKETING_PROCESS_AUTOMATION} element={<MarketingProcessAndAutomation />} />
                    <Route path={ROUTES.ADVISORY_SERVICES.BRANDING_STRATEGY_CONSULTING} element={<BrandingStrategyAndConsulting />} />
                    <Route path={ROUTES.ADVISORY_SERVICES.COMMUNITY_MASTERMIND_CREATION} element={<CommunityAndMastermindCreation />} />

                    {/* Agency Routes */}
                    <Route path={ROUTES.AGENCY} element={<Agency />} />
                    <Route path={ROUTES.AGENCY_SERVICES.COMMUNITY_AND_MASTERMIND_CREATION} element={<CommunityAndMastermindCreation />} />
                    <Route path={ROUTES.AGENCY_SERVICES.SOCIAL_MEDIA_MANAGEMENT} element={<SocialMediaManagement />} />
                    <Route path={ROUTES.AGENCY_SERVICES.SOCIAL_MEDIA_MARKETING} element={<SocialMediaMarketing />} />
                    <Route path={ROUTES.AGENCY_SERVICES.PAY_PER_CLICK} element={<PayPerClick />} />
                    <Route path={ROUTES.AGENCY_SERVICES.PERFORMANCE_MARKETING} element={<PerformanceMarketing />} />
                    <Route path={ROUTES.AGENCY_SERVICES.DIGITAL_MEDIA_PLANNING_AND_BUYING} element={<DigitalMediaPlanningAndBuying />} />
                    <Route path={ROUTES.AGENCY_SERVICES.SEARCH_ENGINE_OPTIMIZATION} element={<SearchEngineOptimization />} />
                    <Route path={ROUTES.AGENCY_SERVICES.CONTENT_MARKETING_SERVICE} element={<ContentMarketingService />} />
                    <Route path={ROUTES.AGENCY_SERVICES.EMAIL_MARKETING_SERVICE} element={<EmailMarketingService />} />
                    <Route path={ROUTES.AGENCY_SERVICES.MARKETING_AUTOMATION} element={<MarketingAutomation />} />
                    <Route path={ROUTES.AGENCY_SERVICES.VIDEO_CONTENT_PRODUCTION} element={<VideoContentProduction />} />
                    <Route path={ROUTES.AGENCY_SERVICES.CREATIVE_SOLUTIONS} element={<CreativeSolutions />} />
                    <Route path={ROUTES.AGENCY_SERVICES.VIDEO_MARKETING} element={<VideoMarketing />} />
                    <Route path={ROUTES.AGENCY_SERVICES.FUNNEL_BUILDING_MARKETING} element={<FunnelBuildingMarketing />} />
                    <Route path={ROUTES.BLOG} element={<Blogs/>} />

                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Main